<template>
  <span>©{{ currentYear }} Mandell's Pharmacy</span>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  computed: {
    currentYear(): number {
      return new Date().getFullYear()
    },
  },
})
</script>
