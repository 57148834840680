<template>
  <v-stepper-step
    :step="step"
    color="secondary"
    :complete="currentStep > step || (completeWhenArrived && currentStep === step)"
  >
    <div class="d-flex flex-column align-center step">
      <span class="tracking-header">{{ label }}</span>
      <span v-if="date !== null && currentStep === step" class="tracking-date"> ({{ dateForDisplay }}) </span>
    </div>
  </v-stepper-step>
</template>

<script>
import { getFormattedDate } from "../../utils/dateUtils"

export default {
  props: {
    step: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      default: null,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    completeWhenArrived: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    dateForDisplay() {
      return getFormattedDate(this.date)
    },
  },
}
</script>

<style lang="scss" scoped>
.tracking-header {
  text-align: center;
  font-size: 1.8rem;
}

.tracking-date {
  font-weight: normal;
  margin-top: 0.5rem;
  color: var(--text);
  font-size: 1.4rem;
}
</style>
