export class InvalidCredentialsError extends Error {
  constructor(message: string) {
    super(message)
    Object.setPrototypeOf(this, InvalidCredentialsError.prototype)
  }
}

export class MissingFieldsError extends Error {
  constructor() {
    super("Missing required fields")
    Object.setPrototypeOf(this, MissingFieldsError.prototype)
  }
}

export class InvalidLoginError extends Error {
  userMustAgreeToTermsAndConditions: boolean

  userMustUpdatePassword: boolean

  userTimedOut: boolean

  constructor(
    message: string,
    userMustAgreeToTermsAndConditions: boolean,
    userMustUpdatePassword: boolean,
    userTimedOut: boolean
  ) {
    super(message)
    Object.setPrototypeOf(this, InvalidCredentialsError.prototype)
    this.userMustAgreeToTermsAndConditions = userMustAgreeToTermsAndConditions
    this.userMustUpdatePassword = userMustUpdatePassword
    this.userTimedOut = userTimedOut
  }
}
