<template>
  <v-autocomplete
    v-if="!loading"
    :id="id"
    v-model="selected"
    :items="items"
    attach
    :disabled="disabled"
    :menu-props="{
      attach: true,
      offsetY: true,
      maxHeight: 100,
    }"
    class="selector"
    color="secondary"
    @change="handleSelect"
  >
    <p>test</p>
    <template #label>
      <div class="select-label"><slot name="label"></slot></div>
    </template>

    <template #item="{ item, attrs, on }">
      <v-list-item v-bind="attrs" color="secondary" v-on="on">
        <v-list-item-content class="select-item">
          <v-icon v-if="selected === item" class="check">check</v-icon>
          <v-list-item-title
            :id="attrs['aria-labelledby']"
            :class="attrs['aria-selected']"
            :style="`padding-left: ${contentPadding}rem`"
            v-text="item.text || item"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedItem: {
      type: String,
      default: null,
    },
    mandellsAdmin: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Boolean,
      default: false,
    },
    patients: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    contentPadding: {
      type: String,
      default: "3.5",
    },
    disableSearch: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selected: "",
    id: null,
  }),
  mounted() {
    const id = `menu-${Math.floor(Math.random() * 1000)}`
    this.id = id

    if (this.items && this.selectedItem === null) {
      const [item] = this.items
      this.selected = item.value || item
      this.$emit("selected", this.selected)
    }
    if (this.items && this.selectedItem) {
      this.selected = this.selectedItem
    }

    if (this.disableSearch) {
      this.$nextTick(() => {
        const inputEl = document.getElementById(id)

        inputEl.setAttribute("readonly", true)
      })
    }
  },
  methods: {
    handleSelect() {
      this.$emit("selected", this.selected)
    },
  },
}
</script>

<style lang="scss" scoped>
.selector {
  position: relative;
  border: 1px solid #979797;
  background-color: white;
  text-transform: none;
  padding: 0.5rem 1.5rem;
  flex-grow: 0.6;
  font-weight: 400;
  :before {
    content: none !important;
  }
  :after {
    content: none !important;
  }
}

::v-deep {
  .v-label--active,
  .v-text-field {
    color: var(--text) !important;
    transform: translateY(0) !important;
  }
  .v-select__selections {
    color: var(--secondary) !important;
    font-size: 1.4rem !important;
    line-height: 1.9rem;
    letter-spacing: 0.009rem;
    padding-bottom: 0.1rem;
    .v-select__selection--comma {
      margin: 0;
    }
  }
  .theme--light,
  .v-select,
  .v-select__selection--comma {
    color: var(--secondary) !important;
  }

  .select-label {
    color: var(--text) !important;
  }

  .v-label {
    top: 0.7rem !important;
    padding-right: 1rem;
    position: static !important;
  }

  .v-input__slot {
    margin: 0;
  }

  .v-text-field__details {
    display: none;
  }

  .v-list-item {
    min-height: 0px !important;
    padding: 0;
  }

  .v-list {
    padding: 0;
  }

  .v-list-item__title {
    font-size: 1.4rem;
    line-height: 3.4rem;
    color: var(--text);
    padding-left: 1rem;
  }

  .v-menu__content {
    box-shadow: none;
    border-radius: 0;
    max-height: 70vh !important;
    max-width: calc(100% + 0.4rem);
    width: calc(100% + 0.4rem);
    min-width: 0 !important;
    margin-left: -0.2rem;
    left: 0 !important;
    // top: -0.2rem !important;
    border: 0.2rem solid #979797;
  }
}

#list-45 {
  background-color: var(--secondary-light);
  padding: 1rem 0;
}

.select-item {
  padding: 0.5rem 1.3rem;
  flex-wrap: nowrap;
  border: 0.1rem solid var(--secondary-light);
  background-color: var(--secondary-light);
}

.check {
  font-size: 1.4em;
  width: 1.5rem;
  padding: 0;
  margin: 0;
  display: flex !important;
  flex: 0 0 auto;
}

.true {
  color: var(--secondary);
  padding-left: 2rem;
}

.select-label {
  font-size: 1.4rem;
  line-height: 1.9rem;
  letter-spacing: 0.009rem;
}
</style>
