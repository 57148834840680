<template>
  <div class="user-content-container">
    <div class="user-content-title-container">
      <v-btn text class="user-content-title-btn" color="var(--text)" @click="handleCancel($event)"
        ><v-icon color="user-content-icon">close</v-icon>Cancel</v-btn
      >
      <span class="divider">|</span>
      <h1 class="user-content-title"><slot name="title"></slot></h1>
    </div>
    <div v-if="loading" class="full-page-loading-spinner">
      <v-progress-circular v-if="loading" indeterminate color="secondary" :size="120"></v-progress-circular>
    </div>
    <v-form v-else class="users-form">
      <div
        v-if="mandellsAdmin && clinics !== null && isEditingSelf === false"
        class="user-clinic-select d-flex flex-column"
      >
        <MandellsMenu
          :items="clinicItems"
          :loading="loading"
          :selected-item="selectedClinicId"
          @selected="handleSelect"
        >
          Select Clinic
        </MandellsMenu>
        <div class="user-clinic-switch">
          <UserSwitch :is-clinic-admin="formData.isClinicAdmin" @input="handleRoleChange" />
        </div>
      </div>
      <div v-else-if="clinicAdmin && isEditingSelf === false" class="user-clinic-select">
        <UserSwitch :is-clinic-admin="formData.isClinicAdmin" @input="handleRoleChange" />
      </div>
      <div v-else class="reg-user"></div>
      <div class="user-input-container">
        <MandellsTextInput
          v-model="formData.lastName"
          :required="true"
          :error="errorOccurred"
          style="flex: 0 0 35%"
          :disabled="submitting"
        >
          Last Name
        </MandellsTextInput>
        <MandellsTextInput
          v-model="formData.firstName"
          :required="true"
          :error="errorOccurred"
          style="flex: 0 0 35%"
          :disabled="submitting"
        >
          First Name
        </MandellsTextInput>
        <MandellsTextInput v-model="formData.middleInitial" style="flex: 0 0 10%" :disabled="submitting">
          MI
        </MandellsTextInput>
        <div class="suffix-container" style="flex: 0 0 12%">
          <span class="suffix-title">Suffix</span>
          <MandellsMenu
            v-if="suffixOptions"
            class="suffix-menu"
            :items="suffixOptions"
            content-padding="1"
            :disabled="submitting"
            :loading="loading"
            :selected-item="formData.suffix"
            @selected="handleSuffixSelect"
          />
        </div>
        <MandellsTextInput
          v-if="isEditingSelf === false"
          v-model="formData.email"
          :error="errorOccurred"
          :required="true"
          style="flex: 0 0 47.5%"
          :disabled="submitting"
        >
          Email
        </MandellsTextInput>
        <MandellsTextInput
          v-model="formData.phone"
          :phone="true"
          :required="true"
          style="flex: 0 0 47.5%"
          :disabled="submitting"
        >
          Phone
        </MandellsTextInput>
      </div>
      <SubmitBtn :loading="submitting" @submit-click="handleSubmission($event)"
        ><slot name="submit-label"></slot
      ></SubmitBtn>
    </v-form>
  </div>
</template>

<script>
import { getAllClinics } from "@/services/clinicServices"
import { getSuffixOptions, getUserInformationById } from "@/services/userInformationServices"
import { InvalidCredentialsError } from "@/utils/errors"
import SubmitBtn from "./SubmitBtn.vue"
import MandellsTextInput from "./MandellsTextInput.vue"
import UserSwitch from "./UserSwitch.vue"
import MandellsMenu from "./MandellsMenu.vue"

export default {
  components: { SubmitBtn, MandellsTextInput, UserSwitch, MandellsMenu },
  props: {
    mandellsAdmin: {
      type: Boolean,
      default: null,
    },
    clinicAdmin: {
      type: Boolean,
      default: null,
    },
    user: {
      type: Boolean,
      default: null,
    },
    currentUser: {
      type: Object,
      default: () => ({}),
    },
    errorOccurred: {
      type: Boolean,
      default: false,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    formData: {
      lastName: null,
      firstName: null,
      middleInitial: null,
      suffix: null,
      email: null,
      phone: null,
      isClinicAdmin: false,
      clinicId: null,
    },
    clinic: null,
    clinicUserId: null,
    selectedClinicId: null,
    selectedUser: null,
    role: null,
    loading: true,
    visible: false,
    clinics: null,
    clinicNames: [],
    suffixOptions: null,
    selectedSuffix: "N/A",
  }),
  computed: {
    isEditingSelf() {
      if (this.clinicUserId === this.currentUser.id) {
        return true
      }
      return false
    },
    isAddingUser() {
      if (this.clinicUserId === null) {
        return true
      }
      return false
    },
    clinicItems() {
      return this.clinics.map(clinic => ({
        text: clinic.name,
        value: clinic.id,
      }))
    },
  },
  async mounted() {
    if (this.$route.params.userId !== undefined) {
      this.clinicUserId = this.$route.params.userId
    }
    if (this.isAddingUser === false) {
      await this.initializeClinicUser()
    }
    await this.checkUserRole()
  },
  methods: {
    handleCancel(event) {
      this.$emit("cancel-click", event)
    },

    async initializeClinicUser() {
      if (this.isAddingUser === false) {
        if (this.isEditingSelf) {
          this.setUserDataIntoForm(this.currentUser)
          this.fetchSuffixOptions()
        } else {
          const userInformation = await this.fetchUserInformation(this.clinicUserId)
          this.setUserDataIntoForm(userInformation)
          this.fetchSuffixOptions()
        }
      }
      this.selectedClinicId = this.formData.clinicId
    },

    setUserDataIntoForm(userData) {
      this.selectedUser = userData
      this.formData.lastName = userData.lastName
      this.formData.firstName = userData.firstName
      this.formData.middleInitial = userData.middleInitial
      this.formData.suffix = this.setUserSuffixIntoFormData(userData.suffix)
      this.formData.phone = userData.phone

      if (this.isEditingSelf) {
        this.formData.isClinicAdmin = null
      } else {
        this.role = userData.role
        this.clinic = userData.clinic
        this.formData.isClinicAdmin = this.setUserClinicAdmin(this.role)
        this.formData.email = userData.email
      }
    },

    async fetchUserInformation(userId) {
      try {
        const response = await getUserInformationById(userId)
        return response
      } catch (error) {
        throw new InvalidCredentialsError("User not found")
      }
    },

    async checkUserRole() {
      if (!this.mandellsAdmin) {
        if (this.user) {
          this.$router.push("/patients")
        } else if (this.clinicAdmin) {
          this.fetchSuffixOptions()
          this.formData.clinicId = this.currentUser.clinicId
          this.loading = false
        }
      } else {
        await this.showMandellsAdmin()
        this.loading = false
      }
    },

    handleSelect(value) {
      const updatedClinic = this.clinics.find(clinic => clinic.id === value)
      if ((this.mandellsAdmin && this.isEditingSelf === false) || this.isAddingUser) {
        this.formData.clinicId = updatedClinic.id
      } else {
        this.formData.clinicId = null
      }
    },

    setUserSuffixIntoFormData(value) {
      if (value === "") {
        return "N/A"
      }
      return value
    },

    async fetchSuffixOptions() {
      const suffixOptions = await getSuffixOptions()
      suffixOptions[0] = "N/A"
      this.suffixOptions = suffixOptions
    },

    handleSuffixSelect(value) {
      if (value === "N/A") {
        this.selectedSuffix = ""
      } else {
        this.selectedSuffix = value
      }
      this.formData.suffix = this.selectedSuffix
    },

    setUserClinicAdmin(value) {
      if (value === "clinicAdmin") {
        return true
      }
      return false
    },

    handleRoleChange(value) {
      if (value) {
        this.formData.isClinicAdmin = true
      } else {
        this.formData.isClinicAdmin = false
      }
    },

    async fetchClinics() {
      try {
        const response = await getAllClinics()
        return response
      } catch (error) {
        return error
      }
    },

    getClinicNameInfo() {
      if (this.clinics !== null) {
        const clinicNames = this.clinics.map(clinic => {
          return clinic.name
        })
        return clinicNames
      }
      return null
    },

    async showMandellsAdmin() {
      this.clinics = await this.fetchClinics()
      const clinicNames = await this.getClinicNameInfo()
      clinicNames.forEach(clinicName => this.clinicNames.push(clinicName))
      if (this.isAddingUser === false && this.selectedUser.clinic !== null) {
        this.selectedClinicId = this.selectedUser.clinicId
      }
      this.fetchSuffixOptions()
      this.loading = false
    },

    removeEmptyProperties() {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.formData)) {
        if (value === null) {
          delete this.formData[key]
        }
      }
    },

    checkForMatchingEmail() {
      const updatedFormData = { ...this.formData }
      if (updatedFormData.email !== null && this.isAddingUser === false) {
        if (updatedFormData.email === this.selectedUser.email) {
          delete updatedFormData.email
        }
      }
      return updatedFormData
    },

    handleSubmission(event) {
      event.preventDefault()
      if (this.formData.suffix === "N/A") {
        this.formData.suffix = ""
      }
      if (this.clinicAdmin && this.isAddingUser === false) {
        delete this.formData.clinicId
      }
      this.removeEmptyProperties()
      if (this.isAddingUser === false) {
        if (this.isEditingSelf) {
          this.$emit("submit", { formData: this.formData, id: this.currentUser.id, isEditingSelf: true })
        } else {
          this.$emit("submit", {
            formData: this.checkForMatchingEmail(),
            id: this.selectedUser.id,
            isEditingSelf: false,
          })
        }
      } else {
        this.$emit("submit", { formData: this.formData })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.user-content-container {
  padding: 0 1.5rem;
  height: 100%;
  width: 100%;

  .full-page-loading-spinner {
    display: flex;
    position: absolute;
    top: 20%;
    left: 35rem;
    height: 70% !important;
    width: calc(100% - 40rem);
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .user-content-title-container {
    display: flex;
    align-items: center;

    .user-content-title-btn {
      text-transform: none;
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: 0.009rem;
      padding-left: 0;
    }
    .divider {
      font-weight: 800;
      font-size: 1.8rem;
      margin-right: 1.25rem;
      color: var(--text);
    }

    .user-content-icon {
      font-size: 1.8rem;
      font-weight: 600;
    }

    .user-content-title {
      font-size: 2.4rem;
      font-weight: 400;
      color: var(--text);
    }
  }
}

.suffix-container {
  .suffix-title {
    color: var(--text);
    font-size: 2rem;
    line-height: 2.6rem;
  }

  .suffix-menu {
    margin-top: 1.4rem;
  }
}

.user-input-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.user-clinic-select {
  padding: 2rem 0;
  width: 50%;
  display: flex;
}

.user-clinic-switch {
  padding: 4rem 0 2rem;
}

.reg-user {
  height: 8rem;
}

.error-container {
  color: var(--error);
  margin-left: 1rem;
  font-size: 1.4rem;
}
</style>
