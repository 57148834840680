<template>
  <v-btn text color="var(--text)" class="add-user-btn" @click="handleClick($event)"
    ><v-icon class="add-user-icon">add_box</v-icon>Add User</v-btn
  >
</template>

<script>
export default {
  methods: {
    handleClick(event) {
      this.$emit("add-click", event)
    },
  },
}
</script>

<style lang="scss" scoped>
.add-user-btn {
  text-transform: none;
  font-size: 1.4rem;
  letter-spacing: 0.009rem;

  .add-user-icon {
    margin-right: 0.5rem;
    font-size: 2rem;
  }
}
</style>
