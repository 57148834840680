<template>
  <v-data-table
    class="mandells-table"
    :headers="headersToRender"
    :items="items"
    :item-key="itemKey"
    :no-data-text="noDataText"
    :search="search"
    :loading="loading"
    :loader-height="0"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    hide-default-header
    hide-default-footer
    disable-pagination
    @update:sort-desc="handlePageSortDescendingUpdated"
  >
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    freezeFirstColumn: {
      type: Boolean,
      default: null,
    },
    loading: {
      type: Boolean,
      default: null,
    },
    noDataText: {
      type: String,
      default: "No results",
    },
    sortBy: {
      type: String,
      default: null,
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    fixedHeader: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headersToRender() {
      if (this.editable === true) {
        return this.headers.map(header => {
          if (header.text === "Name") {
            return { ...header, sortable: true }
          }
          return header
        })
      }
      return this.headers
    },
  },
  methods: {
    handlePageSortDescendingUpdated(sortDescending) {
      this.$emit("page-sort-descending-updated", sortDescending)
    },
  },
}
</script>

<style lang="scss" scoped>
.mandells-table::v-deep {
  background-color: transparent !important;
}
</style>
