<template>
  <v-btn
    class="submit-btn"
    :style="`margin-bottom: ${marginBottom}; background-color: ${backgroundColor} !important;`"
    depressed
    dark
    :loading="loading"
    :disabled="disabled"
    :outlined="outlined"
    @click="handleClick($event)"
  >
    <h5 class="btn-text"><slot></slot></h5>
  </v-btn>
</template>

<script>
export default {
  props: {
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    marginBottom: {
      type: String,
      default: "4rem",
    },
    backgroundColor: {
      type: String,
      default: "var(--secondary) ",
    },
  },
  methods: {
    handleClick(event) {
      this.$emit("submit-click", event)
    },
  },
}
</script>

<style lang="scss" scoped>
.submit-btn {
  text-transform: none;
  min-width: 25.6rem !important;
  height: 6.6rem !important;
  margin: 0 auto;
  border: 0.3rem solid white !important;
  border-radius: 10rem;
  transition: 0.25s;

  .btn-text {
    color: white;
    letter-spacing: 0.013rem !important;
    line-height: 2.6rem;
    font-weight: 400;
  }
}
.v-btn--disabled {
  border: 0.3rem solid rgba(0, 0, 0, 0.1) !important;

  .btn-text {
    color: rgba(0, 0, 0, 0.3) !important;
  }
}

@media (max-width: 500px) {
  .submit-btn {
    min-width: 20rem !important;
    .btn-text {
      font-size: 1.3rem;
    }
  }
}
</style>
