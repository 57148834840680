<template>
  <v-app>
    <transition name="route" mode="out-in">
      <router-view />
    </transition>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue"
import { getCssVariableValue } from "./utils/getCssVariableValue"

export default Vue.extend({
  beforeMount() {
    // Sync color palette variables with the vuetify theme so we can use css variables and vuetify theme colors interchangeably
    this.$vuetify.theme.themes.light.primary = getCssVariableValue("--primary")
    this.$vuetify.theme.themes.light.secondary = getCssVariableValue("--secondary")
  },
})
</script>

<style>
@import url("./styles/_fonts.scss");
@import url("./styles/_global.scss");
@import url("./styles/_color-palette.scss");

.route-enter-active,
.route-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.route-enter,
.route-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
</style>
