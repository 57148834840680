<template>
  <div class="role-dashboard">
    <div v-if="isLoading" class="loading-page-spinner d-flex justify-center align-center">
      <v-progress-circular v-if="isLoading" indeterminate color="secondary" :size="70"></v-progress-circular>
    </div>
    <div v-else class="container">
      <DashNavDrawer
        style="width: 30rem"
        :mandells-admin="isMandellsAdmin"
        :clinic-admin="isClinicAdmin"
        :user="isUser"
        :current-user="currentUser"
      />
      <div class="content">
        <transition name="route" mode="out-in">
          <router-view
            v-if="!isLoading"
            :current-user="currentUser"
            :mandells-admin="isMandellsAdmin"
            :clinic-admin="isClinicAdmin"
            :user="isUser"
            @submission-result="handleSubmissionResult"
            @submission-result-self="handleSubmissionSelfResult"
          />
        </transition>
        <MandellsSnackbar
          :value="snackbarOpen"
          :variety="resultVariety"
          :text="resultText"
          @close="handleSnackbarClose"
        />
      </div>

      <div class="dashboard-container"></div>
    </div>
  </div>
</template>

<script>
import { getCurrentAuthUserId, getCurrentAuthUserRole, logoutUser } from "@/services/authServices"
import { getUserInformationById } from "@/services/userInformationServices"
import { InvalidCredentialsError } from "@/utils/errors"
import DashNavDrawer from "./DashNavDrawer.vue"
import MandellsSnackbar from "../shared/MandellsSnackbar.vue"

export default {
  components: {
    DashNavDrawer,
    MandellsSnackbar,
  },
  data: () => ({
    isMandellsAdmin: false,
    isClinicAdmin: false,
    isUser: false,
    isLoading: true,
    currentUser: null,
    resultVariety: null,
    resultText: null,
    snackbarOpen: false,
    updatedSelf: false,
  }),
  async mounted() {
    await this.fetchUserInformation()
    await this.fetchUserRole()
  },
  methods: {
    async fetchUserRole() {
      const currentUserRole = await getCurrentAuthUserRole()
      if (currentUserRole) {
        if (currentUserRole === "mandellsAdmin") {
          this.isMandellsAdmin = true
          this.pushUserBasedOnRole()
        } else if (currentUserRole === "clinicAdmin") {
          this.isClinicAdmin = true
          this.pushUserBasedOnRole()
        } else if (currentUserRole === "clinicUser") {
          this.isUser = true
          this.pushUserBasedOnRole()
        } else {
          logoutUser()
          this.$router.push("/sign-in")
        }
        this.isLoading = false
      } else {
        this.currentUser = null
        this.isLoading = false
        logoutUser()
        this.$router.push("/sign-in")
      }
    },

    async fetchUserInformation() {
      try {
        const userId = getCurrentAuthUserId()
        this.currentUser = await getUserInformationById(userId)
      } catch (error) {
        throw new InvalidCredentialsError("User not found")
      }
    },

    pushUserBasedOnRole() {
      if (this.$route.path === "/") {
        if (this.isMandellsAdmin || this.isClinicAdmin) {
          this.$router.push("/users")
        } else {
          this.$router.push("/patients")
        }
      }
    },

    handleSubmissionResult(value) {
      this.resultVariety = value.type
      this.resultText = value.message
      this.snackbarOpen = true
    },

    handleSubmissionSelfResult(value) {
      this.handleSubmissionResult(value)
      this.fetchUserInformation()
      this.fetchUserRole()
    },

    handleSnackbarClose() {
      this.snackbarOpen = false
      this.resultVariety = null
      this.resultText = null
    },
  },
}
</script>

<style lang="scss" scoped>
.role-dashboard {
  height: 100vh;
  max-width: 144rem;
  padding-right: 8rem;
  width: 100vw;
  margin: 0 auto;

  .loading-page-spinner {
    height: 100%;
  }
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
}
.content {
  width: calc(100% - 30rem);
  padding: 3.5rem 2.8rem 7rem 2.8rem;
  margin-top: 3rem;
  margin-left: 0.6rem;
  overflow-y: hidden;
  z-index: 3;
}

.dashboard-container {
  height: calc(100% - 2rem);
  width: calc(100% - 30rem);
  margin-right: 8rem;
  padding: 3.5rem 2.8rem 7rem 2.8rem;
  border-radius: 3rem;
  border-top: 0.6rem solid var(--primary-light);
  border-right: 0.6rem solid var(--primary-light);
  border-bottom: 0.6rem solid var(--primary-light);
  border-left: 0.6rem solid var(--primary-light);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, var(--primary) 100%);
  position: absolute;
  left: 30rem;
  bottom: 0;
  z-index: 1;
  &::after {
    content: "";
    background: linear-gradient(to top, white 50%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    width: calc(100% + 1.2rem);
    height: 50%;
    left: -0.6rem;
    bottom: -0.6rem;
    z-index: 2;
  }
}

.route-enter-active,
.route-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.route-enter,
.route-leave-to {
  opacity: 0;
  transform: translateX(1rem);
}
</style>
