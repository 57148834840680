import { render, staticRenderFns } from "./MandellsMenu.vue?vue&type=template&id=6cc474c8&scoped=true"
import script from "./MandellsMenu.vue?vue&type=script&lang=js"
export * from "./MandellsMenu.vue?vue&type=script&lang=js"
import style0 from "./MandellsMenu.vue?vue&type=style&index=0&id=6cc474c8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cc474c8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VAutocomplete,VIcon,VListItem,VListItemContent,VListItemTitle})
