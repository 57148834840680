<template>
  <div>
    <v-snackbar class="mandells-snackbar" :value="value" :color="color" @input="handleInput($event)">
      {{ text }}

      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="handleClose()"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from "vue"

const SUCCESS_VARIETY = "success"
const ERROR_VARIETY = "error"

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
    variety: {
      type: String,
      default: null,
    },
  },
  computed: {
    color(): string {
      if (this.variety === SUCCESS_VARIETY) {
        return "success"
      }

      if (this.variety === ERROR_VARIETY) {
        return "error"
      }

      return "secondary"
    },
  },
  watch: {
    value(currentValue, previousValue) {
      if (currentValue === true && previousValue === false) {
        setTimeout(() => {
          this.handleClose()
        }, 3000)
      }
    },
  },
  methods: {
    handleInput(event: Event) {
      this.$emit("input", { type: this.variety, message: event })
    },
    handleClose() {
      this.$emit("close")
    },
  },
})
</script>

<style lang="scss">
.v-snack__content {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}
</style>
