<template>
  <div class="sign-in-container">
    <div class="sign-in-background"></div>
    <div class="sunflower top-left"></div>
    <div class="sunflower bottom-right"></div>
    <div class="sign-in-content">
      <div class="sign-in-logo">
        <MandellsLogoLarge />
      </div>
      <UserLogin :error-occurred="errorOccurred" @submission-result="handleSubmissionResult" />
    </div>
    <MandellsSnackbar :value="snackbarOpen" :variety="resultVariety" :text="resultText" @close="handleSnackbarClose" />
  </div>
</template>

<script>
import MandellsSnackbar from "@/components/shared/MandellsSnackbar.vue"
import UserLogin from "../components/UserLogin/UserLogin.vue"
import MandellsLogoLarge from "../components/shared/icons/MandellsLogoLarge.vue"

export default {
  components: { UserLogin, MandellsSnackbar, MandellsLogoLarge },
  data: () => ({
    resultVariety: null,
    resultText: null,
    snackbarOpen: false,
    errorOccurred: false,
  }),
  methods: {
    handleSubmissionResult(value) {
      this.resultVariety = value.type
      this.resultText = value.message
      this.snackbarOpen = true

      if (value.type !== "success") {
        this.errorOccurred = true
      }
    },
    handleSnackbarClose() {
      this.snackbarOpen = false
      this.errorOccurred = false
    },
  },
}
</script>

<style scoped lang="scss">
.sign-in-container {
  border: 0.1rem solid rgb(212, 214, 212);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  padding: 9.16% 6.11%;
}

.sign-in-background {
  background: url("../assets/baby-feet-background.jpg") no-repeat, center;
  background-size: cover;
  opacity: 20;
  height: 100%;
  width: 106.69%;
  position: fixed;
}

.sunflower {
  max-width: 273px;
  max-height: 258px;
  height: 34.96%;
  width: 26.68%;
  background: url("../assets/sunflowers-top.png") no-repeat, center;
  background-size: contain;
  position: fixed;

  &.top-left {
    top: 0;
    left: 0;
  }

  &.bottom-right {
    bottom: 0;
    right: 0;
    transform: rotate(180deg);
    z-index: 2;
  }
}

.sign-in-content {
  width: 100%;
  max-width: 126.2rem;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-in-logo {
  width: 33.6%;
  height: 40.41%;
  min-height: 30rem;
  margin-right: 9.26%;
}

@media (max-width: 1180px) {
  .sign-in-content {
    margin-top: 24rem;
    flex-direction: column;
  }

  .sign-in-logo {
    margin-right: 0;
    width: 30rem;
    min-height: 20rem;
  }
}

@media (max-width: 800px) {
  .sunflower {
    &.bottom-right {
      z-index: 1;
    }
  }
}

@media (max-width: 700px) {
  .sign-in-content .container {
    min-width: auto;
    width: 100%;
  }
}
</style>
