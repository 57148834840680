import { render, staticRenderFns } from "./DashDrawerPhone.vue?vue&type=template&id=fe7c4e1e&scoped=true"
import script from "./DashDrawerPhone.vue?vue&type=script&lang=js"
export * from "./DashDrawerPhone.vue?vue&type=script&lang=js"
import style0 from "./DashDrawerPhone.vue?vue&type=style&index=0&id=fe7c4e1e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe7c4e1e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
installComponents(component, {VListItem})
