<template>
  <!-- eslint-disable vue/no-template-shadow  -->
  <div :class="expand ? 'orders-table-container' : 'tall-table'">
    <MandellsTable
      :headers="headers"
      :items="orders"
      item-key="id"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :header-class="'orders-header'"
      :header-cell-class="'orders-header-cell'"
    >
      <template #header="{ props: { headers } }">
        <thead class="orders-header">
          <tr>
            <th v-for="header in headers" :key="header.text" class="orders-header-cell">
              <div>
                <v-btn
                  class="cell-header"
                  text
                  plain
                  :ripple="false"
                  :disabled="header.sortable === false"
                  @click="() => handleHeaderClicked(header)"
                  >{{ header.text }}</v-btn
                >
                <v-icon v-if="sortBy !== header.value && header.sortable !== false" class="header-arrow-hover-indicator"
                  >arrow_upward</v-icon
                >
                <v-icon v-if="sortBy === header.value && !sortDesc">arrow_upward</v-icon>
                <v-icon v-if="sortBy === header.value && sortDesc">arrow_downward</v-icon>
              </div>
            </th>
          </tr>
        </thead>
      </template>

      <template #body="props">
        <div v-if="tableLoading">
          <v-progress-linear class="loading-bar" color="secondary" height="10" indeterminate></v-progress-linear>
        </div>

        <tbody v-else ref="tbody" class="orders-body">
          <tr v-for="item in props.items" :key="item.id" class="orders-body-row">
            <td class="orders-body-cell">
              <span class="cell-body">
                {{ convertDateTime(item.date) }}
              </span>
            </td>
            <td class="orders-body-cell">
              <span class="cell-body">
                {{ useChangeToTitleCase(item.medicationName) }}
              </span>
            </td>
            <td class="orders-body-cell">
              <span class="cell-body">
                {{ useChangeToTitleCase(item.prescribedBy) }}
              </span>
            </td>

            <td class="orders-body-cell">
              <div v-if="item.needByDate !== ''" class="orders-body-flex">
                <span class="cell-body">{{ convertDateTime(item.needByDate) }}</span>
                <ChangeReqBtn class="mt-2" subject="Need By Date">Change Request</ChangeReqBtn>
              </div>
              <div v-else class="orders-body-flex"></div>
            </td>

            <td class="orders-body-cell status">
              <div class="orders-body-flex">
                <span class="cell-body">{{ refineOrderStatus(item.status) }}</span>
                <span v-if="shouldShowStatusDate(item)" class="cell-body status"
                  >({{ convertDateTime(item.date) }})</span
                >
                <v-btn
                  v-if="shouldShowTrackLink(item.statusCode)"
                  class="track-btn"
                  color="secondary"
                  text
                  @click="handleTracking(item)"
                  >Track</v-btn
                >
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </MandellsTable>
  </div>
</template>

<script>
import Vue from "vue"
import MandellsTable from "../shared/MandellsTable.vue"
import ChangeReqBtn from "./ChangeReqBtn.vue"
import { changeToTitleCase } from "../../utils/changeToTitleCase"
import { getFormattedDate } from "../../utils/dateUtils"
import { PrescriptionStatusCodes } from "../../constants/prescriptionStatusCodes"

export default Vue.extend({
  components: { MandellsTable, ChangeReqBtn },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    orders: {
      type: Array,
      default: () => [],
    },
    expand: {
      type: Boolean,
      default: false,
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    scroll: false,
    dialog: false,
    sortBy: "date",
    sortDesc: true,
  }),

  mounted() {
    const table = this.$refs.tbody
    table.addEventListener("scroll", this.handleScroll)
  },

  beforeUnmount() {
    const table = this.$refs.tbody
    table.removeEventListener("scroll", this.handleScroll)
  },

  methods: {
    useChangeToTitleCase(value) {
      return changeToTitleCase(value)
    },

    handleScroll() {
      if (this.orders.length > 6) {
        const table = this.$refs.tbody
        const scrollPosition = table.scrollTop
        if (scrollPosition > 4) {
          this.scroll = true
          this.$emit("scrolled")
        } else {
          this.scroll = false
          this.$emit("at-top")
        }
      }
    },

    handleTracking(value) {
      this.$emit("tracking", value)
    },

    convertDateTime(value) {
      return getFormattedDate(value)
    },

    refineOrderStatus(value) {
      if (value === "PickedUp") {
        return "Picked Up"
      }
      if (value === "RphChecked") {
        return "Rph Checked"
      }
      return value
    },
    shouldShowTrackLink(statusCode) {
      return (
        statusCode === PrescriptionStatusCodes.PackagedForDelivery ||
        statusCode === PrescriptionStatusCodes.Shipped ||
        statusCode === PrescriptionStatusCodes.Delivered
      )
    },
    shouldShowStatusDate(item) {
      return item.statusCode !== PrescriptionStatusCodes.Delivered
    },
    handleHeaderClicked(header) {
      if (this.sortBy !== header.value) {
        this.sortBy = header.value
      } else if (this.sortBy === header.value && !this.sortDesc) {
        this.sortDesc = true
      } else {
        this.sortBy = null
        this.sortDesc = false
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.tall-table {
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  &::v-deep {
    tbody {
      display: block;
      max-height: 59.5vh;
      overflow-y: scroll;
    }
    tbody::-webkit-scrollbar {
      display: none;
    }
  }
}

.orders-table-container {
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::v-deep {
    tbody {
      display: block;
      max-height: 40.5vh;
      overflow-y: scroll;
    }
    tbody::-webkit-scrollbar {
      display: none;
    }
  }
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.orders-header-cell {
  position: relative;
  .cell-header {
    color: var(--text) !important;
    opacity: 1 !important;
    font-size: 1.4rem;
    line-height: 3.2rem;
    letter-spacing: 0.009rem;
    font-weight: 400;
    text-transform: none;

    &::v-deep .v-btn__content {
      opacity: 1 !important;
    }
  }

  .header-arrow-hover-indicator {
    opacity: 0;
    position: absolute;
    top: 1.2rem;
  }

  &:hover {
    .header-arrow-hover-indicator {
      opacity: 0.5;
    }
  }
}

.orders-body-row {
  vertical-align: top;
  height: 10.8rem;

  .orders-body-cell {
    padding: 1.8rem 1.6rem;
  }

  .cell-body {
    color: var(--text);
    font-size: 1.4rem;
    line-height: 2.2rem;
    letter-spacing: 0.11px;
  }

  .orders-body-flex {
    display: flex;
    flex-direction: column;
    a {
      color: var(--secondary);
      font-size: 1.2rem;
    }

    .status {
      color: var(--text);
      font-size: 1.2rem;
    }
  }
  .track-btn {
    font-size: 1.2rem;
    letter-spacing: 0.009rem;
    height: 100%;
    width: 4rem;
    min-height: 0;
    min-width: 0;
    align-items: start;
    justify-content: flex-start;
    text-transform: none;
    text-decoration: underline;
    padding: 0;
  }
}

.orders-body-row:hover {
  transition: 0.25s;
  background-color: rgba(0, 125, 195, 0.1) !important;
}
</style>
