<template>
  <v-switch
    v-model="isAdmin"
    inset
    class="mandells-switch"
    color="secondary"
    :class="{ on: isAdmin }"
    @change="onChange()"
  >
    <template #label> Clinic Admin </template>
  </v-switch>
</template>

<script>
export default {
  props: {
    isClinicAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAdmin: false,
    }
  },
  mounted() {
    if (this.isClinicAdmin === false) {
      this.isAdmin = false
    } else {
      this.isAdmin = true
    }
  },
  methods: {
    onChange() {
      this.$emit("input", this.isAdmin)
    },
  },
}
</script>

<style lang="scss" scoped>
.mandells-switch {
  width: 17rem;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &::v-deep {
    label {
      color: var(--text);
      font-size: 1.6rem;
      line-height: 1.7rem;
      left: 0rem !important;
    }

    .v-input__slot {
      flex-direction: row-reverse;
    }

    .v-input--switch__track {
      background-color: var(--text);
      opacity: 1;
      height: 2.1rem;
      width: 89%;
      margin: 0.3rem 0 0 0;
    }

    .v-input--switch__thumb {
      color: var(--primary-light) !important;
      margin-top: 0.15rem;
      height: 1.5rem;
      width: 1.5rem;
      transform: translate(0, 0) !important;
    }
  }
}

.on::v-deep {
  label {
    color: var(--secondary);
  }
  .v-input--switch__track {
    background-color: var(--secondary) !important;
  }
}
</style>
