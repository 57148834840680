<template>
  <div id="patients-container" class="patients-container">
    <MandellsSearch
      :value="search"
      :expand="expand"
      placeholder="Patients Search"
      @input="debouncedHandler"
    ></MandellsSearch>
    <div v-if="loading" class="loading-page-spinner d-flex justify-center align-center">
      <v-progress-circular v-if="loading" indeterminate color="secondary" :size="70"></v-progress-circular>
    </div>
    <div v-else :class="expand ? 'tall' : 'table-wrapper'">
      <PatientsTable
        :headers="headers"
        :patients="clinicPatients"
        :keys="statusKeys"
        :search="search"
        :sort-keys="sortKeys"
        :expand="expand"
        :loading="loading || updatingSort"
        :has-next-page="hasNextPage"
        :selected-sort-key="selectedSortKey"
        @sort-select="handleSortSelected"
        @scrolled="handleScrolled"
        @at-top="handleNoScroll"
        @add-clinic-patients="handleInitializeObserver"
        @patient-click="handlePatientClick"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from "vue-debounce"
import { DEBOUNCE_INTERVAL } from "@/constants/debounceInterval"
import { getAllClinicPatients } from "@/services/clinicServices"
import PatientsTable from "../components/Patients/PatientsTable.vue"
import MandellsSearch from "../components/shared/MandellsSearch.vue"

export default {
  name: "Patients",
  components: { MandellsSearch, PatientsTable },
  props: {
    currentUser: {
      type: Object,
      default: null,
    },
    mandellsAdmin: {
      type: Boolean,
      default: false,
    },
    clinicAdmin: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    search: null,
    errorOccurred: false,
    resultVariety: null,
    resultText: null,
    snackbarOpen: false,
    pageSize: 15,
    pageNumber: 1,
    paginated: true,
    hasNextPage: null,
    totalPatientsCount: 0,
    sortBy: "lastName",
    sortDirection: "ASC",
    observer: null,
    observedElement: null,
    addClinicPatientsPosition: null,
    addingMorePatients: false,
    expand: false,
    loading: true,
    updatingSort: false,
    initialDataLoaded: false,
    headers: [
      { text: "Name", value: "name" },
      { text: "Date of Birth", value: "dob" },
      { text: "Status", value: "status" },
    ],
    clinicPatients: [],
    statusKeys: ["All", "New", "Refill", "In Process", "Shipped", "Transferred Out", "Delivered", "Restricted Access"],
    sortKeys: ["A - Z", "Z - A"],
    selectedSortKey: "A - Z",
  }),
  created() {
    this.debouncedHandler = debounce(async event => {
      await this.searchPatients(event)
    }, DEBOUNCE_INTERVAL)
  },
  mounted() {
    if (!this.mandellsAdmin) {
      this.$nextTick(async () => {
        await this.initializePatientsTable()
        this.loading = false
      })

      this.loading = false
    } else {
      this.$router.push("/users")
    }
  },
  methods: {
    handleScrolled() {
      this.expand = true
    },
    handleNoScroll() {
      this.expand = false
    },

    async initializePatientsTable() {
      if (this.initialDataLoaded) {
        this.updatingSort = true
      } else {
        this.loading = true
      }

      const clinicPatients = await this.fetchClinicPatients()
      this.clinicPatients = clinicPatients
      this.loading = false
      this.updatingSort = false
      this.initialDataLoaded = true
    },

    async fetchClinicPatients() {
      const response = await getAllClinicPatients(
        this.$props.currentUser.clinicId,
        this.sortBy,
        this.sortDirection,
        this.search
      )
      return this.combineNameProperties(response)
    },

    combineNameProperties(arr) {
      const updatedArr = arr.map(arr => {
        if (arr.middleInitial) {
          return {
            ...arr,
            name: `${arr.lastName}, ${arr.firstName}, ${arr.middleInitial}`,
          }
        }
        return {
          ...arr,
          name: `${arr.lastName}, ${arr.firstName}`,
        }
      })
      return updatedArr
    },

    handlePatientClick(value) {
      const patient = value
      this.$router.push({
        name: "SinglePatient",
        params: {
          patientId: patient.id,
        },
      })
    },

    async searchPatients(value) {
      this.loading = true
      this.search = value
      this.pageNumber = 1
      this.hasNextPage = false
      await this.initializePatientsTable()
    },

    handleInitializeObserver() {
      const selectedClinicPatients = this.clinicPatients[this.addClinicPatientsPosition]
      const selectedClinicPatientId = selectedClinicPatients.id
      this.initObserver(selectedClinicPatientId)
    },

    initObserver(value) {
      const trackingElement = document.getElementById(`${value}`)
      this.observeredElement = document.getElementById("patients-container")

      const options = {
        root: this.observeredElement,
        threshold: [...Array(10)].map((el, index) => 0.1 * index),
      }

      const handleIntersectionEvent = async entry => {
        if (entry[0].isIntersecting && this.hasNextPage === true && this.addingMorePatients === false) {
          this.addingMorePatients = true
          await this.addMoreClinicPatients()
        }
      }

      this.observer = new IntersectionObserver(handleIntersectionEvent, options)
      this.observer.observe(trackingElement)
    },

    async addMoreClinicPatients() {
      this.observer.unobserve(this.observeredElement)
      this.pageNumber += 1
      const newPatients = await this.fetchClinicPatients()
      this.clinicPatients = [...this.clinicPatients, ...newPatients]
      this.addingMorePatients = false
    },

    async handleSortSelected(value) {
      if (value === "A - Z") {
        this.sortDirection = "ASC"
        this.selectedSortKey = value
      } else if (value === "Z - A") {
        this.sortDirection = "DESC"
        this.selectedSortKey = value
      }
      this.pageNumber = 1
      this.hasNextPage = false
      await this.initializePatientsTable()
    },
  },
}
</script>

<style lang="scss" scoped>
.patients-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table-wrapper {
  transition: 1s;
}
.tall {
  transform: translateY(-8rem);
  transition: 1s;
}

.loading-page-spinner {
  height: 80%;
  width: 100%;
}
</style>
