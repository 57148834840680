<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <span class="text" v-bind="attrs" v-on="on">
        {{ value }}
      </span>
    </template>
    <span class="tooltip-text">{{ value }}</span>
  </v-tooltip>
</template>

<script lang="ts">
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.text {
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: 0.011rem;
}
</style>
