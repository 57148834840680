import firebase from "firebase/app"
import "firebase/auth"

export const getCurrentAuthUserOrThrowError = () => {
  if (firebase.auth().currentUser == null) {
    throw Error("No current user found")
  } else {
    return firebase.auth().currentUser as firebase.User
  }
}

export const getCurrentAuthUserId = () => getCurrentAuthUserOrThrowError().uid

export const getCurrentAuthUserRole = async (): Promise<String> => {
  const idTokenResult = await firebase.auth().currentUser?.getIdTokenResult()
  return idTokenResult?.claims.role
}

const logOutAuth = async () => firebase.auth().signOut()

export const logoutUser = async () => {
  return logOutAuth()
}
