<template>
  <div class="header">
    <span color="secondary" class="cursive">Welcome to</span>
    <span class="header">Mandell's Clinical Pharmacy</span>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.header {
  height: 15.85%;
  font-family: var(--font-caps);
  text-align: center;
  font-size: 3.6rem;
  line-height: 5rem;
  letter-spacing: 0.021rem;
  color: var(--secondary);

  .cursive {
    font-family: var(--font-cursive);
    font-size: 3.2rem;
    letter-spacing: 0.013rem;
    display: block;
  }
}

@media (max-width: 700px) {
  .header {
    margin-bottom: 3rem;
  }
}

@media (max-width: 500px) {
  .header {
    margin-bottom: 2rem;
    line-height: 1;
  }
}
</style>
