import axios, { AxiosRequestConfig, Method } from "axios"

export const makeUnauthenticatedRequest = async (
  url: string,
  // eslint-disable-next-line default-param-last
  method: Method = "GET",
  body?: any,
  logError = true,
  responseType: AxiosRequestConfig["responseType"] = "json"
) => {
  try {
    const response = await axios({
      method,
      url,
      data: body,
      responseType,
    })
    return response.data
  } catch (error) {
    if (logError) {
      // eslint-disable-next-line no-console
      console.error(`An error occured while fetching data for url: '${url}', error`)
    }
    return Promise.reject(error)
  }
}
