import { makeAuthenticatedRequest } from "@/utils/makeAuthenticatedRequest"
import { getClinicsUrl, getPatientsUrl } from "@/utils/urls"
import { addSearchQuery, addSortBySortDirectionToQuery } from "./authRequestServices"

export const getAllClinics = async () => {
  const response = await makeAuthenticatedRequest(getClinicsUrl(), "GET")
  return response
}

export const getAllClinicPatients = async (
  clinicId: string,
  sortBy: string,
  sortDirection: string,
  search?: string
) => {
  const response = await makeAuthenticatedRequest(
    `${getClinicsUrl()}/${clinicId}/patients?${addSearchQuery(search)}${addSortBySortDirectionToQuery(
      sortBy,
      sortDirection
    )}`,
    "GET"
  )
  return response
}
