import { InvalidLoginError, InvalidCredentialsError, MissingFieldsError } from "@/utils/errors"
import { makeAuthenticatedRequest } from "@/utils/makeAuthenticatedRequest"
import { getAuthorizedTokenUrl, getUserUrl } from "@/utils/urls"
import { AuthorizedTokenResponse } from "@/types/AuthorizeTokenResponse"
import axios, { AxiosError } from "axios"
import firebase from "firebase/app"
import "firebase/auth"
import { makeUnauthenticatedRequest } from "@/utils/makeUnauthenticatedRequest"

const handleAuthError = (error: AxiosError) => {
  // When errorOccurred is true, we know an expected error occurred server-side -- these expected errors are due to expired credentials
  if (error.response?.data.errorOccurred) {
    throw new InvalidLoginError(
      "Expired credentials",
      error.response.data.userMustAgreeToTermsAndConditions,
      error.response.data.userMustUpdatePassword,
      error.response.data.userInTimeout
    )
  } else {
    throw new InvalidCredentialsError("Unable to verify user.")
  }
}

export const createTokenForFirebaseLogin = async (
  password: string,
  email?: string,
  userId?: string
): Promise<AuthorizedTokenResponse> => {
  try {
    return await makeUnauthenticatedRequest(getAuthorizedTokenUrl(), "POST", {
      email,
      userId,
      password,
    })
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAuthError(error)
    }

    await firebase.auth().signOut()

    throw new InvalidCredentialsError("Email or Password incorrect.")
  }
}

export const login = async (password: string, emailAddress?: string, userId?: string) => {
  if ((emailAddress || userId) && password) {
    const authTokenResponse = await createTokenForFirebaseLogin(password, emailAddress, userId)
    return firebase.auth().signInWithCustomToken(authTokenResponse.token)
  }
  throw new MissingFieldsError()
}

export const forgotPassword = async (emailAddress: string) => {
  try {
    return await makeAuthenticatedRequest(`${getUserUrl()}/forgot-password/${emailAddress}`, "POST")
  } catch (error) {
    throw new InvalidCredentialsError(`Unable to reset password of ${emailAddress}`)
  }
}

export const getCurrentAuthUser = () => firebase.auth().currentUser

export const addSortBySortDirectionToQuery = (sortBy?: string, sortDirection?: string) =>
  sortBy && sortDirection ? `&sortBy=${sortBy}&sortDirection=${sortDirection}` : ``
export const addSearchQuery = (search?: string) => (search ? `search=${search}&` : "")
export const addPaginationQuery = (paginated?: boolean, pageSize?: string, pageNumber?: string) =>
  paginated && pageSize && pageNumber ? `pageSize=${pageSize}&pageNumber=${pageNumber}` : ``
export const addClinicId = (clinicId?: string) => (clinicId ? `clinicId=${clinicId}&` : ``)
