<template>
  <div class="phone-container">
    <v-list-item v-for="item in phoneInfo" :key="item.description" class="content">
      <p class="description">{{ item.description }}</p>
      <h6 class="body1 number">{{ item.number }}</h6>
    </v-list-item>
  </div>
</template>

<script>
export default {
  data: () => ({
    phoneInfo: [
      {
        description: "Main Number",
        number: "(877) 252-0553",
      },
      {
        description: "Main Fax",
        number: "(877) 252-0450",
      },
      {
        description: "Donor Number",
        number: "(855) 626-3357",
      },
      {
        description: "Donor Fax",
        number: "(855) 366-6779",
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
.phone-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 0rem;
  padding: 0 !important;
  margin-bottom: 0.8rem;
}

.description {
  font-size: 1.3rem;
  letter-spacing: 0.011rem;
  line-height: 2rem;
  font-weight: 400;
  margin: 0;
  color: var(--text);
}
.number {
  color: var(--secondary);
}
</style>
