<template>
  <div :class="expand ? 'patients-table-container' : 'tall-table'">
    <MandellsTable
      :headers="headers"
      :items="patients"
      item-key="id"
      :loading="loading"
      :search="search"
      :header-class="'patients-header'"
    >
      <!-- eslint-disable-next-line vue/no-template-shadow -->
      <template #header="{ props: { headers } }">
        <thead class="patients-header">
          <tr>
            <th class="patients-header-cell">
              <div class="d-flex pb-3" style="width: 80%">
                <MandellsMenu
                  :items="sortKeys"
                  :selected-item="selectedSortKey"
                  :disable-search="true"
                  @selected="handleSortSelected"
                >
                  <template #label>Sort: </template>
                </MandellsMenu>
              </div>
            </th>
            <th class="patients-header-cell">
              <div class="d-flex justify-center align-end">
                <span class="cell-body">{{ headers[1].text }}</span>
              </div>
            </th>
            <th class="patients-header-cell"></th>
          </tr>
        </thead>
      </template>

      <template #body="props">
        <tbody ref="tbody" class="patients-body">
          <div v-if="!loading">
            <tr
              v-for="item in props.items"
              :id="item.id"
              :key="item.id"
              class="patients-body-row"
              @click="handleRouteClick(item)"
            >
              <td class="patients-body-cell">
                <TextWithTooltip
                  :value="`${useChangeToTitleCase(item.lastName)}, ${useChangeToTitleCase(item.firstName)}`"
                />
              </td>
              <td class="patients-body-cell">
                <div class="d-flex justify-center">
                  <span class="cell-body">
                    {{ convertDateTime(item.birthDate) }}
                  </span>
                </div>
              </td>
              <td class="patients-body-cell">
                <span class="cell-body"> </span>
              </td>
            </tr>
          </div>
          <div v-else class="d-flex justify-center mt-3">
            <v-progress-circular indeterminate color="secondary" />
          </div>
        </tbody>
      </template>
    </MandellsTable>
  </div>
</template>

<script>
import { changeToTitleCase } from "@/utils/changeToTitleCase"
import MandellsMenu from "../shared/MandellsMenu.vue"
import MandellsTable from "../shared/MandellsTable.vue"
import TextWithTooltip from "../shared/TextWithTooltip.vue"
import { getFormattedDate } from "../../utils/dateUtils"

export default {
  components: { MandellsTable, MandellsMenu, TextWithTooltip },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    patients: {
      type: Array,
      default: () => [],
    },
    sortKeys: {
      type: Array,
      default: () => [],
    },
    keys: {
      type: Array,
      default: () => [],
    },
    expand: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: null,
    },
    hasNextPage: {
      type: Boolean,
      default: false,
    },
    selectedSortKey: {
      type: String,
      default: "A - Z",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    scroll: false,
  }),
  updated() {
    this.$nextTick(function finishedUpdating() {
      if (this.hasNextPage === true) {
        this.$emit("add-clinic-patients")
      }
    })
  },
  mounted() {
    const table = this.$refs.tbody
    table.addEventListener("scroll", this.handleScroll)
  },
  beforeUnmount() {
    const table = this.$refs.tbody
    table.removeEventListener("scroll", this.handleScroll)
  },

  methods: {
    handleScroll() {
      const table = this.$refs.tbody
      const scrollPosition = table.scrollTop
      if (scrollPosition > 4) {
        this.scroll = true
        this.$emit("scrolled")
      } else {
        this.scroll = false
        this.$emit("at-top")
      }
    },
    handleRouteClick(value) {
      this.$emit("patient-click", value)
    },

    convertDateTime(value) {
      return getFormattedDate(value)
    },

    useChangeToTitleCase(value) {
      return changeToTitleCase(value)
    },

    handleSortSelected(value) {
      this.$emit("sort-select", value)
    },
  },
}
</script>

<style lang="scss" scoped>
.tall-table {
  height: 68vh;
  width: 100%;
  position: relative;
  display: flex;
  transition: 0.5s;
  margin-top: 2rem;

  &::v-deep {
    tbody {
      position: relative;
      display: block;
      height: 74vh;
      overflow-y: scroll;
      transition: 0.5s;
    }
    tbody::-webkit-scrollbar {
      display: none;
    }
  }
}

.patients-table-container {
  display: flex;
  margin-top: 2rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::v-deep {
    tbody {
      display: block;
      max-height: 83vh;
      overflow-y: scroll;
    }
    tbody::-webkit-scrollbar {
      display: none;
    }
  }
}

tbody tr {
  display: table;
  width: 98%;
  margin-left: 0.5%;
  table-layout: fixed;
}

thead {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 0;
}

.patients-header {
  padding-right: 2rem;

  .patients-header-cell {
    border-bottom: 0.1rem solid var(--text) !important;
    .cell-body {
      color: var(--text);
      font-size: 1.4rem;
      line-height: 3.2rem;
      letter-spacing: 0.009rem;
      font-weight: 400;
    }
  }
}
.patients-body-row {
  vertical-align: middle;
  height: 5.8rem;
  border-radius: 1.5rem;
  background-color: white;
  box-shadow: 0.1rem 0.1rem 0.8rem 0 rgba(0, 0, 0, 0.21);
  margin: 1rem auto;
  font-size: 1.8rem;
  letter-spacing: 0.012rem;
  color: var(--text);
  cursor: pointer;

  .patients-body-cell {
    padding: 1rem 1.6rem;
    border-bottom: none !important;
  }

  .cell-body {
    color: var(--text);
    font-size: 1.6rem;
    line-height: 2.2rem;
    letter-spacing: 0.011rem;
  }
}

.patients-header-cell:first-child,
.patients-body-cell:first-child {
  padding-left: 3rem;
}

.patients-header-cell:last-child,
.patients-body-cell:last-child {
  text-align: right;
  padding-right: 3rem;
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .column-btn {
    text-transform: none;
    color: var(--text);
    font-size: 1.6rem;
    letter-spacing: 0.012rem;
    font-weight: 400;
    padding: 0 1rem;

    .btn-icon {
      font-size: 2rem;
      padding-right: 0.25rem;
    }
  }
}

.patients-body-row:hover {
  transition: 0.25s;
  background-color: var(--secondary-light) !important;
  .patients-body-cell {
    .cell-body {
      transition: 0.25s;
      color: var(--secondary) !important;
    }
  }
}

::v-deep {
  .patients-body-row:hover {
    .text {
      transition: 0.25s;
      color: var(--secondary) !important;
    }
  }
}
</style>
