<template>
  <v-text-field
    v-mask="mask"
    :type="type"
    :value="value"
    :error="error"
    :disabled="disabled"
    :error-messages="errorMessages"
    :rules="required !== false ? [requiredRule] : []"
    :append-icon="appendIcon"
    color="secondary"
    class="input d-flex align-end"
    @click:append="handleClickAppend($event)"
    @input="handleInput($event)"
    @blur="handleBlur($event)"
    @keydown.enter="handleEnterPressed($event)"
  >
    <template #label>
      <h5 class="form-label">
        <slot></slot>
      </h5>
    </template>
  </v-text-field>
</template>

<script lang="ts">
import Vue from "vue"
import { required as requiredRule } from "vuelidate/lib/validators"

const PHONE_MASK = "+#(###)###-####"

export default Vue.extend({
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    phone: {
      type: Boolean,
      default: null,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      requiredRule,
    }
  },
  computed: {
    mask() {
      return this.phone === null ? null : PHONE_MASK
    },
  },
  methods: {
    handleInput(value: string) {
      this.$emit("input", value)
    },
    handleBlur(value: Event) {
      this.$emit("blur", value)
    },
    handleEnterPressed(value: Event) {
      this.$emit("enter-pressed", value)
    },
    handleClickAppend(value: Event) {
      this.$emit("append", value)
    },
  },
})
</script>

<style lang="scss" scoped>
.input {
  height: 8.4rem;
  max-height: 8.4rem;
  font-size: 2rem;
  line-height: 2.6rem;
  padding: 0;
  margin: 0 0 4.7rem 0;
}

.form-label {
  font-weight: 400;
}

::v-deep {
  .v-input__control,
  .v-input__slot,
  .v-text-field__slot {
    height: 100%;
  }
  .theme--light,
  .v-input,
  input {
    color: var(--text) !important;
  }

  .v-input__icon,
  .v-input__icon--append {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }

  .v-input__control {
    flex-direction: row !important;
  }

  .theme--light,
  .v-text-field,
  .v-input__control,
  .v-input__slot:before,
  .v-input__slot:after {
    border-color: var(--text);
  }

  .v-label {
    position: absolute;
    height: 100%;
    margin-bottom: 5.7rem;
    color: var(--text) !important;
  }
  .v-label--active {
    transform: none !important;
  }

  input {
    position: absolute;
    bottom: 0;
  }
}

.loading-enter-active,
.loading-leave-active {
  transition: all 1s ease-out;
}
.loading-enter,
.loading-leave-to {
  opacity: 0;
}
</style>
