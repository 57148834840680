<template>
  <div class="single-container">
    <div v-if="loading" class="loading-page-spinner d-flex justify-center align-center">
      <v-progress-circular v-if="loading" indeterminate color="secondary" :size="70"></v-progress-circular>
    </div>
    <div v-else>
      <div class="information-card-container">
        <InformationCards :expand="expand" :patient-data="patientData" />
      </div>
      <h4 class="med-orders-title">Medication Orders</h4>
      <div v-if="!medOrderError" class="med-orders-container">
        <div v-if="tableLoading" class="loading-page-spinner d-flex justify-center align-center">
          <v-progress-circular indeterminate color="secondary" :size="70"></v-progress-circular>
        </div>
        <OrdersTable
          v-else
          :headers="headers"
          :orders="medOrders"
          :expand="expand"
          :table-loading="tableLoading"
          @scrolled="handleScrolled"
          @at-top="handleNoScroll"
          @tracking="handleTracking"
        />
      </div>
      <div v-else class="med-orders-container d-flex justify-center">
        <span class="med-orders-error">Unable to find Medication Orders</span>
      </div>
    </div>

    <MandellsDialog :open="open" :dialog-max-width="700" :title="selectedMedicationName" @dialog-closed="closeModal()">
      <template #content>
        <div v-if="!trackingLoading && trackingInfo !== null" class="tracking-info-wrapper">
          <div class="tracking-link-wrapper">
            <span>Tracking Number:</span
            ><a
              v-if="isTrackingValid(trackingInfo.shippingType)"
              class="tracking-link"
              :href="getTrackingUrl(trackingInfo.trackingNumber, trackingInfo.shippingType)"
              target="_blank"
              >{{ trackingInfo.trackingNumber }}</a
            >
            <p v-else class="ml-2">{{ trackingInfo.trackingNumber }}</p>
          </div>
          <MandellsStepper :step="selectedMedicationStep">
            <template #content>
              <TrackingStep :step="1" label="In Process" date="" :current-step="selectedMedicationStep" />
              <v-divider></v-divider>
              <TrackingStep :step="2" label="Packaged for Delivery" date="" :current-step="selectedMedicationStep" />
              <v-divider></v-divider>
              <TrackingStep :step="3" label="Shipped" date="" :current-step="selectedMedicationStep" />
              <v-divider></v-divider>
              <TrackingStep :step="4" label="Delivered" :current-step="selectedMedicationStep" complete-when-arrived />
            </template>
          </MandellsStepper>
        </div>
        <div v-else class="tracking-loader d-flex justify-center align-center">
          <v-progress-circular indeterminate color="secondary" :size="70"></v-progress-circular>
        </div>
      </template>
    </MandellsDialog>
  </div>
</template>

<script>
import {
  getPatientById,
  getPatientMedicationOrders,
  getPatientMedicationOrderTrackingInformation,
} from "@/services/patientServices"
import { getFedexTrackingUrl, getUPSTrackingUrl, FEDEX_SHIP_TYPE, UPS_SHIP_TYPE } from "../utils/shippingUtils"
import MandellsDialog from "../components/shared/MandellsDialog.vue"
import MandellsStepper from "../components/shared/MandellsStepper.vue"
import InformationCards from "../components/SinglePatient/InformationCards.vue"
import TrackingStep from "../components/SinglePatient/TrackingStep.vue"
import OrdersTable from "../components/SinglePatient/OrdersTable.vue"
import { PrescriptionStatusCodes } from "../constants/prescriptionStatusCodes"

export default {
  components: {
    InformationCards,
    OrdersTable,
    MandellsDialog,
    MandellsStepper,
    TrackingStep,
  },
  props: {
    currentUser: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    expand: true,
    open: false,
    loading: true,
    trackingLoading: false,
    trackingInfo: null,
    tableLoading: true,
    patientData: {},
    selectedMedicationName: "",
    selectedMedicationStep: 1,
    selectedMedicationStatusDate: "",
    headers: [
      {
        text: "Date:",
        value: "date",
      },
      {
        text: "Medication:",
        value: "medication",
        sortable: false,
      },
      {
        text: "Prescribed by:",
        value: "prescribedBy",
        sortable: false,
      },
      {
        text: "Need by Date:",
        value: "needByDate",
      },
      {
        text: "Status:",
        value: "status",
        sortable: false,
      },
    ],
    medOrders: null,
    medOrderError: false,
  }),
  async mounted() {
    await this.initializeSinglePatient()
  },
  methods: {
    handleScrolled() {
      if (this.expand !== false) {
        this.expand = false
      }
    },
    handleNoScroll() {
      if (this.expand !== true) {
        this.expand = true
      }
    },
    closeModal() {
      this.open = false
    },

    async initializeSinglePatient() {
      this.tableLoading = true
      const patient = this.$route.params
      this.patientData = await this.fetchPatient(patient.patientId)
      this.loading = false
      this.medOrders = await this.fetchPatientMedicationOrders(patient.patientId, this.$props.currentUser.clinicId)

      this.tableLoading = false
    },

    async fetchPatient(patientId) {
      try {
        const response = await getPatientById(patientId)
        return response
      } catch (error) {
        this.$emit("submission-result", { type: "error", message: error.message })
        return null
      }
    },

    async fetchPatientMedicationOrders(patientId, clinicId) {
      try {
        const response = await getPatientMedicationOrders(patientId, clinicId)
        return response
      } catch (error) {
        this.medOrderError = true
        this.$emit("submission-result", { type: "error", message: error.message })
        return null
      }
    },

    async fetchTrackingInformation(clinicId, patientId, scriptNumber, fillNumber) {
      try {
        const response = await getPatientMedicationOrderTrackingInformation(patientId, scriptNumber, fillNumber)
        return response
      } catch (error) {
        this.$emit("submission-result", { type: "error", message: error.message })
        return null
      }
    },
    getTrackingStep(statusCode) {
      if (statusCode === PrescriptionStatusCodes.InProcess) {
        return 1
      }

      if (statusCode === PrescriptionStatusCodes.PackagedForDelivery) {
        return 2
      }

      if (statusCode === PrescriptionStatusCodes.Shipped) {
        return 3
      }

      if (statusCode === PrescriptionStatusCodes.Delivered) {
        return 4
      }

      return 1
    },

    async handleTracking(prescription) {
      this.trackingLoading = true
      this.selectedMedicationName = prescription.medicationName
      this.open = true
      this.selectedMedicationStep = this.getTrackingStep(prescription.statusCode)
      const trackingInfoResponse = await getPatientMedicationOrderTrackingInformation(
        this.patientData.id,
        prescription.scriptNumber,
        prescription.fillNumber
      )
      this.selectedMedicationStatusDate = prescription.statusDate
      this.trackingInfo = trackingInfoResponse
      this.trackingLoading = false
    },
    getTrackingUrl(trackingNumber, shipType) {
      if (shipType.toLowerCase() === FEDEX_SHIP_TYPE) {
        return getFedexTrackingUrl(trackingNumber)
      }

      if (shipType.toLowerCase() === UPS_SHIP_TYPE) {
        return getUPSTrackingUrl(trackingNumber)
      }

      return ""
    },

    isTrackingValid(shipType) {
      return [FEDEX_SHIP_TYPE, UPS_SHIP_TYPE].includes(shipType.toLowerCase())
    },
  },
}
</script>

<style lang="scss" scoped>
.single-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.loading-page-spinner {
  height: 60%;
}

.tracking-loader {
  width: 100%;
}

.tracking-info-wrapper {
  width: 100%;
}

.med-orders-container {
  height: 100%;
  width: 100%;
}

.med-orders-title {
  color: var(--text);
  line-height: 3.2rem;
  letter-spacing: 0.016rem;
  font-weight: 400;
  margin: 4rem 0 1rem 0;
}

.tracking-link-wrapper {
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  width: 100%;
  color: var(--text);
  margin: 1rem 0rem 3rem 0rem;

  a {
    margin-left: 1rem;
  }
}

.tracking-link {
  text-decoration: underline;
  color: var(--secondary);
}

.stepper-container {
  border: 0rem !important;
  width: 100%;
}
.stepper {
  background-color: transparent !important;

  .step {
    color: var(--text);

    .tracking-information {
      font-size: 1.4rem;
      text-align: center;
      margin-top: 0.4rem;
    }

    .tracking-header {
      font-size: 1.8rem;
      line-height: 2.6rem;
      letter-spacing: 0.012rem;
    }
  }

  &::v-deep {
    .v-stepper__header {
      box-shadow: none;
    }
    .v-stepper__step__step {
      height: 2.2rem;
      width: 2.2rem;
      min-width: 0rem;

      .v-icon {
        font-size: 2rem;
        font-weight: 800;
      }
    }
  }
}

.med-orders-error {
  font-size: 1.8rem;
  color: var(--text);
  margin-top: 10rem;
}
</style>
