<template>
  <v-navigation-drawer class="drawer" permanent width="30rem" height="100%">
    <div class="drawer-content">
      <v-list-item class="logo-container">
        <MandellsLogo class="logo" />
      </v-list-item>
      <v-tabs v-if="mandellsAdmin" vertical color="secondary" class="tab-container">
        <v-tab v-for="item in mandellsAdminItems" :key="item.name" link :to="item.route" color="secondary">
          <v-list-item-title class="nav-link">
            {{ item.name }}
          </v-list-item-title>
        </v-tab>
      </v-tabs>

      <v-tabs v-if="clinicAdmin" vertical color="secondary" class="tab-container">
        <v-tab v-for="item in clinicItems" :key="item.name" link :to="item.route" color="secondary">
          <v-list-item-title class="nav-link">
            {{ item.name }}
          </v-list-item-title>
        </v-tab>
      </v-tabs>
      <v-tabs v-if="user" vertical color="secondary" class="tab-container">
        <v-tab v-for="item in userItems" :key="item.name" link :to="item.route" color="secondary">
          <v-list-item-title class="nav-link">
            {{ item.name }}
          </v-list-item-title>
        </v-tab>
      </v-tabs>
      <v-divider style="border-color: var(--text)"></v-divider>
      <div v-if="loading" class="loading-container">
        <v-progress-circular v-if="loading" indeterminate color="secondary" :size="50"></v-progress-circular>
      </div>
      <DashDrawerUser v-else :current-user="currentUser" :mandells-admin="mandellsAdmin" />
      <v-divider style="border-color: var(--text)"></v-divider>
      <DashDrawerPhone />
      <p class="copyright cap"><CopyrightText /></p>
    </div>
  </v-navigation-drawer>
</template>

<script>
import MandellsLogo from "../shared/icons/MandellsLogo.vue"
import DashDrawerPhone from "./DashDrawerPhone.vue"
import DashDrawerUser from "./DashDrawerUser.vue"
import CopyrightText from "../shared/CopyrightText.vue"

export default {
  components: {
    MandellsLogo,
    DashDrawerUser,
    DashDrawerPhone,
    CopyrightText,
  },
  props: {
    mandellsAdmin: {
      type: Boolean,
      default: false,
    },
    clinicAdmin: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Boolean,
      default: false,
    },
    currentUser: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    mandellsAdminItems: [{ name: "Users", route: "/users" }],
    clinicItems: [
      { name: "Users", route: "/users" },
      { name: "Patients", route: "/patients" },
    ],
    userItems: [{ name: "Patients", route: "/patients" }],
  }),
  computed: {
    loading() {
      if (this.currentUser === null) {
        return true
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.drawer {
  width: 30rem;
  position: relative;
  z-index: 3;
}

.drawer-content {
  margin: 0 4.6rem 0 6rem;
  position: relative;
  padding: 6.4rem 0 5.6rem 0;
  height: 100%;
}

.logo-container {
  width: 100%;
  padding: 0;
}
.logo {
  height: 16%;
  width: 100%;
}

.loading-container {
  margin: 30% 0%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-container {
  max-height: 17rem;
  height: 19%;
}

.nav-link {
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: 0.013rem;
  text-align: right;
  padding-right: 2.5rem;
  text-transform: none;
  font-weight: 400;
}

.copyright {
  color: var(--text);
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 2%;
}

::v-deep {
  .v-tabs-slider-wrapper {
    color: var(--primary);
    right: 0rem;
    left: auto !important;
    width: 0.6rem !important;
  }
  .v-tab:not(.v-tab--active) {
    color: var(--text) !important;
  }
  .v-slide-group {
    align-items: center;
  }
  .v-navigation-drawer__border {
    background-color: transparent !important;
  }
}
</style>
