import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
import { getCurrentAuthUser } from "@/services/authRequestServices"
import SignIn from "../views/SignIn.vue"
import Dashboard from "../views/Dashboard.vue"
import RoleBasedDashboard from "../components/Dashboard/RoleBasedDashboard.vue"
import Patients from "../views/Patients.vue"
import SinglePatient from "../views/SinglePatient.vue"
import ClinicUsersPage from "../views/ClinicUsersPage.vue"
import AddClinicUser from "../views/AddClinicUser.vue"
import EditUser from "../views/EditUser.vue"
import SignUp from "../views/SignUp.vue"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
    alias: "/forgot-password",
  },
  {
    path: "/",
    component: Dashboard,
    children: [
      {
        path: "",
        name: "RoleBasedDashboard",
        component: RoleBasedDashboard,
        children: [
          {
            path: "/patients",
            name: "Patients",
            component: Patients,
          },
          {
            path: "/patients/:patientId",
            name: "SinglePatient",
            component: SinglePatient,
          },
          {
            path: "/users",
            name: "ClinicUsersPage",
            component: ClinicUsersPage,
          },
          {
            path: "/users/add-user",
            name: "AddClinicUser",
            component: AddClinicUser,
          },
          {
            path: "/users/:userId/edit",
            name: "EditUser",
            component: EditUser,
          },
        ],
      },
    ],
  },
]

const UNPROTECTED_ROUTES = ["SignIn", "SignUp"]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

const userMustBeLoggedIn = (routeName?: string | null) => !routeName || !UNPROTECTED_ROUTES.includes(routeName)

router.beforeEach(async (to, from, next) => {
  if (userMustBeLoggedIn(to.name) && getCurrentAuthUser() === null) {
    return next({ name: "SignIn" })
  }

  return next()
})

export default router
