<template>
  <div>
    <MandellsDialog
      class="dialog"
      :persistent="agreementRequired"
      :open="open"
      :dialog-max-width="550"
      title-color="var(--secondary);"
      @dialog-closed="closeTermsAndConditions"
    >
      <template #dialog-title>Terms and Conditions</template>
      <template #content>
        <div v-if="loading" class="loading-container">
          <v-progress-circular indeterminate color="secondary" :size="40"></v-progress-circular>
        </div>
        <div v-else class="d-flex flex-column dialog-content">
          <!--eslint-disable-next-line vue/no-v-html-->
          <div class="terms-content"><span v-html="termsHtml"></span></div>
          <div v-if="agreementRequired" class="terms-checkbox d-flex flex-column justify-end align-center">
            <LoginCheckbox v-model="isUserTermsConditionsAgreed" :required="true" />
            <p v-if="errorOccurred">An error occurred</p>
            <SubmitBtn
              :loading="updatingUser"
              margin-bottom="0rem"
              :outlined="true"
              :disabled="!isUserTermsConditionsAgreed"
              @submit-click="handleAgreeToTermsAndConditions"
              >Sign In
            </SubmitBtn>
          </div>
        </div>
      </template>
    </MandellsDialog>
  </div>
</template>

<script>
import { getTermsAndConditions, updateTermsAndConditionsDate } from "@/services/userInformationServices"
import MandellsDialog from "../shared/MandellsDialog.vue"
import SubmitBtn from "../shared/SubmitBtn.vue"
import LoginCheckbox from "./LoginCheckbox.vue"

export default {
  components: { MandellsDialog, LoginCheckbox, SubmitBtn },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: null,
    },
    password: {
      type: String,
      default: null,
    },
    userSignUp: {
      type: Boolean,
      default: false,
    },
    agreementRequired: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    termsHtml: null,
    isUserTermsConditionsAgreed: false,
    termsAndConditionsDialogOpen: false,
    updatingUser: false,
    userInformation: null,
    loading: true,
    errorOccurred: false,
  }),
  async mounted() {
    await this.fetchTermsAndConditions()
  },
  methods: {
    async fetchTermsAndConditions() {
      this.termsHtml = await getTermsAndConditions()
      this.loading = false
    },

    async handleUserUpdate() {
      this.errorOccurred = false

      try {
        await updateTermsAndConditionsDate(this.email, this.password)
        this.isUserTermsConditionsAgreed = true
        this.closeTermsAndConditions(this.isUserTermsConditionsAgreed)
      } catch (error) {
        this.errorOccurred = true
      }
    },

    closeTermsAndConditions() {
      this.updatingUser = false

      if (this.agreementRequired) {
        this.$emit("terms-agreed-to", this.isUserTermsConditionsAgreed)
      } else {
        this.$emit("closed")
      }
    },

    handleAgreeToTermsAndConditions() {
      if (!this.userSignUp) {
        this.updatingUser = true
        this.handleUserUpdate()
      } else {
        this.updatingUser = true
        this.$emit("terms-agreed-to", true)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.terms-content {
  color: var(--text);
  font-size: 1.4rem;
  letter-spacing: 0.009rem;
  max-height: 30rem;
  overflow-y: scroll;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5rem 0 8rem 0;
}

.dialog-content {
  width: 100%;
}
</style>
