<template>
  <div class="dashboard-container">
    <transition name="route" mode="out-in">
      <router-view />
    </transition>
    <MandellsDialog
      class="dialog"
      :open="inactivityDialogOpen"
      :dialog-max-width="650"
      title-color="var(--secondary);"
      @dialog-closed="closeInactivityDialog"
    >
      <template #dialog-title>Session Interval</template>
      <template #content>
        <div class="d-flex flex-column justify-center align-center dialog-content">
          <div class="inactivity-dialog-content">
            You're being timed out due to inactivity. Please choose to remain signed in or you will be signed out
            automatically.
          </div>
          <div class="inactivity-btn-container d-flex justify-center align-center">
            <v-btn
              class="inactivity-btn btn-left"
              color="var(--secondary-light)"
              depressed
              dark
              outlined
              x-large
              @click="closeInactivityDialog"
            >
              Sign Out
            </v-btn>
            <v-btn
              class="inactivity-btn btn-right"
              color="var(--secondary)"
              depressed
              dark
              x-large
              @click="handleConfirmStillActive"
            >
              Stay Signed In ({{ inactivityDialogCountdown }})
            </v-btn>
          </div>
        </div>
      </template>
    </MandellsDialog>
  </div>
</template>

<script>
import MandellsDialog from "@/components/shared/MandellsDialog.vue"
import { logoutUser } from "@/services/authServices"

export default {
  components: { MandellsDialog },
  data: () => ({
    isUserActive: true,
    inactivityDialogOpen: false,
    inactivityDialogCountdown: 59,
    idleMinutes: 0,
    idleInterval: null,
    dialogInterval: null,
  }),
  mounted() {
    window.addEventListener("scroll", this.resetIdleTime)
    window.addEventListener("click", this.resetIdleTime)
    window.addEventListener("mousemove", this.resetIdleTime)
    window.addEventListener("keydown", this.resetIdleTime)
    this.initializeInactivityCheck()
  },

  beforeUnmount() {
    this.removeInactivityCheck()
    window.removeEventListener("scroll", this.resetIdleTime)
    window.removeEventListener("click", this.resetIdleTime)
    window.removeEventListener("mousemove", this.resetIdleTime)
    window.removeEventListener("keydown", this.resetIdleTime)
  },
  methods: {
    async closeInactivityDialog() {
      if (this.isUserActive) {
        this.resetTimers()
        this.initializeInactivityCheck()
        this.inactivityDialogOpen = false
      } else {
        this.isUserActive = true
        this.resetTimers()
        this.inactivityDialogOpen = false
        await this.handleSignOff()
      }
    },

    async handleConfirmStillActive() {
      this.isUserActive = true
      await this.closeInactivityDialog()
    },

    initializeInactivityCheck() {
      this.idleMinutes = 0
      this.inactivityDialogCountdown = 59
      this.idleInterval = null
      this.dialogInterval = null
      this.idleInterval = setInterval(
        function timerFunction() {
          this.incrementIdleTime()
          // eslint-disable-next-line no-extra-bind
        }.bind(this),
        60000
      )
    },

    incrementIdleTime() {
      if (this.idleMinutes < 14) {
        this.idleMinutes += 1
      } else {
        clearInterval(this.idleInterval)
        if (this.inactivityDialogOpen === false) {
          this.setupInactivityDialogCountdown()
        }
      }
    },

    setupInactivityDialogCountdown() {
      this.inactivityDialogOpen = true
      this.isUserActive = false
      this.dialogInterval = setInterval(() => {
        this.countdownInactivityDialog()
      }, 1000)
    },

    countdownInactivityDialog() {
      if (this.inactivityDialogCountdown < 1) {
        this.closeInactivityDialog()
      } else {
        this.inactivityDialogCountdown -= 1
      }
    },

    resetTimers() {
      clearInterval(this.dialogInterval)
      this.inactivityDialogCountdown = 59
      this.idleMinutes = 0
    },

    resetIdleTime() {
      if (this.idleMinutes !== 0) {
        this.idleMinutes = 0
      }
    },

    async handleSignOff() {
      await logoutUser()
      this.$router.push("/sign-in")
    },

    removeInactivityCheck() {
      clearInterval(this.dialogInterval)
      this.dialogInterval = null
      clearInterval(this.idleInterval)
      this.idleInterval = null
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard-container {
  height: 100vh;
  max-width: 144rem;
  margin: 0 auto;
  width: 100vw;
}
.route-enter-active,
.route-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.route-enter,
.route-leave-to {
  opacity: 0;
  transform: translateX(1rem);
}

.inactivity-dialog-content {
  // border: 1px solid red;
  width: 75%;
  color: var(--text);
  font-size: 1.4rem;
  line-height: 1.9rem;
  letter-spacing: 0.009rem;
  transition: 0.1s;
  margin-top: 2rem;
}

.inactivity-btn-container {
  margin-top: 5rem;
}

.inactivity-btn {
  border-radius: 10rem;
  text-transform: none;
  font-size: 1.4rem;
  width: 80%;
}

.btn-left {
  margin-right: 3rem;
  background-color: var(--secondary-light);
  border: 0.1rem solid var(--secondary) !important;
  color: var(--secondary);
}
</style>
