<template>
  <!-- eslint-disable vue/no-template-shadow -->
  <div class="users-table-container">
    <MandellsTable
      :search="search"
      :headers="headers"
      :items="usersWithCurrentUserFirst"
      item-key="id"
      :header-class="'users-header'"
    >
      <template #header="{ props: { headers } }">
        <thead class="users-header">
          <tr>
            <th v-for="header in headers" :key="header.text" class="users-header-cell">
              <span v-if="header.text === 'Name'" class="cell-header">
                {{ header.text }}
                <v-btn class="header-sort-btn" color="var(--text)" icon x-small @click="handleSortDirectionClick">
                  <v-icon v-if="sortDirection === 'ASC'" x-small>arrow_downward</v-icon>
                  <v-icon v-if="sortDirection === 'DESC'" x-small>arrow_upward</v-icon>
                </v-btn>
              </span>
              <span v-else class="cell-header">{{ header.text }}</span>
            </th>
            <th class="users-header-cell"></th>
          </tr>
        </thead>
      </template>

      <template #body="props">
        <tbody ref="tbody" class="users-body">
          <tr v-for="item in props.items" :id="item.id" :key="item.id" class="users-body-row">
            <td class="users-body-cell text-truncate">
              <TextWithTooltip :value="item.name" />
            </td>
            <td class="users-body-cell">
              <span class="cell-body">
                {{ item.suffix }}
              </span>
            </td>

            <td class="users-body-cell text-truncate">
              <TextWithTooltip :value="item.email" />
            </td>

            <td class="users-body-cell">
              <span class="cell-body">
                {{ phoneMask(item.phone) }}
              </span>
            </td>

            <td class="users-body-cell button-container">
              <SplitButton
                v-if="currentUser.id !== item.id"
                edit
                remove
                @option-one="handleEdit(item)"
                @option-two="handleRemove(item)"
              />
              <SplitButton v-else edit @option-one="handleEdit(item)" />
            </td>
          </tr>
        </tbody>
      </template>
    </MandellsTable>
  </div>
</template>

<script>
import SplitButton from "@/components/shared/SplitButton.vue"
import TextWithTooltip from "@/components/shared/TextWithTooltip.vue"
import MandellsTable from "../shared/MandellsTable.vue"

export default {
  components: { MandellsTable, SplitButton, TextWithTooltip },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Array,
      default: null,
    },
    search: {
      type: String,
      default: null,
    },
    currentUser: {
      type: Object,
      default: () => ({}),
    },
    hasNextPage: {
      type: Boolean,
      default: false,
    },
    sortDirection: {
      type: String,
      default: null,
    },
  },
  computed: {
    usersWithCurrentUserFirst() {
      const currentUser = this.users.find(user => user.id === this.currentUser.id)

      if (typeof this.users === "undefined") {
        return []
      }

      if (currentUser) {
        const listWithoutCurrentUser = this.users.filter(user => user.id !== this.currentUser.id)

        return [currentUser, ...listWithoutCurrentUser]
      }

      return this.users
    },
  },
  updated() {
    this.$nextTick(function finishedUpdating() {
      if (this.hasNextPage === true) {
        this.$emit("add-clinic-users")
      }
    })
  },
  methods: {
    handleEdit(value) {
      this.$emit("edit", value)
    },
    handleRemove(value) {
      this.$emit("remove", value)
    },
    handleSortDirectionClick() {
      this.$emit("change-sort-direction")
    },
    phoneMask(value) {
      const phone = value?.replace(/[^\d]/g, "")
      return phone?.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "($2) $3-$4")
    },
  },
}
</script>

<style lang="scss" scoped>
.users-table-container {
  display: flex;

  &::v-deep {
    tbody {
      display: block;
      max-height: 68.75vh;
      overflow-y: auto;
    }

    tbody::-webkit-scrollbar {
      display: none;
    }
  }
}

.users-table-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

tbody tr {
  display: table;
  width: 98%;
  margin-left: 0.5%;
  table-layout: fixed;
}

thead {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 0;
}

.users-header-cell {
  border-bottom: 0.1rem solid var(--text) !important;
  .cell-header {
    color: var(--text);
    font-size: 1.4rem;
    line-height: 3.2rem;
    letter-spacing: 0.09px;
    font-weight: 400;
  }
}

.users-body-row {
  vertical-align: middle;
  height: 5.8rem;
  border-radius: 1.5rem;
  background-color: white;
  box-shadow: 0.1rem 0.1rem 0.8rem 0 rgba(0, 0, 0, 0.21);
  margin: 1rem auto;
  font-size: 1.8rem;
  letter-spacing: 0.012rem;
  color: var(--text);

  .users-body-cell {
    padding: 1rem 1.6rem;
    border-bottom: none !important;

    &.button-container {
      padding-left: 5rem;
    }
  }

  .cell-body {
    color: var(--text);
    font-size: 1.6rem;
    line-height: 2.2rem;
    letter-spacing: 0.011rem;
  }
}

.users-header-cell:first-child,
.users-body-cell:first-child {
  padding-left: 3rem;
}

.users-header-cell:nth-child(2),
.users-body-cell:nth-child(2) {
  width: 6%;
  padding-left: 0.8rem;
}

.users-header-cell:nth-child(4),
.users-body-cell:nth-child(4) {
  width: 18%;
}

.users-body-row:hover {
  transition: 0.25s;
  background-color: var(--secondary-light) !important;
  .users-body-cell {
    .cell-body {
      transition: 0.25s;
      color: var(--secondary) !important;
    }
  }
}

::v-deep {
  .users-body-row:hover {
    .text {
      transition: 0.25s;
      color: var(--secondary) !important;
    }
  }
}

.header-sort-btn {
  color: var(--text);
}
</style>
