<template>
  <div id="users-container" class="users-container">
    <div class="top-content d-flex">
      <div class="top-left">
        <h1 class="users-title">Users</h1>
        <div style="width: 100%; display: flex"></div>
      </div>
      <div class="top-right">
        <MandellsSearch placeholder="Users Search" @input="debouncedHandler" />
      </div>
    </div>
    <div class="add-user-container">
      <div style="width: 40%; display: flex">
        <MandellsMenu v-if="mandellsAdmin && clinics !== null" :items="clinicNames" @selected="handleSelected">
          <template #label>Select Clinic:</template>
        </MandellsMenu>
      </div>
      <AddUsersBtn @add-click="handleRouteClick" />
    </div>
    <div v-if="loading" class="loading-page-spinner d-flex justify-center align-center">
      <v-progress-circular v-if="loading" indeterminate color="secondary" :size="70"></v-progress-circular>
    </div>
    <div v-else class="table-wrapper">
      <UsersTable
        :search="search"
        :users="clinicUsers"
        :add-clinic-users-position="addClinicUsersPosition"
        :has-next-page="hasNextPage"
        :headers="headers"
        :current-user="currentUser"
        :sort-direction="sortDirection"
        @change-sort-direction="handleChangeSortDirection"
        @add-clinic-users="handleInitializeObserver"
        @edit="handleEditUser"
        @remove="handleRemoveClick"
      />
    </div>
    <MandellsDialog class="dialog" :open="confirmationOpen" :dialog-max-width="800" @dialog-closed="closeConfirmation">
      <template #dialog-title
        >Are you sure you want to delete {{ selectedUser.firstName }} {{ selectedUser.lastName }}?</template
      >
      <template #content>
        <div v-if="dialogLoading" class="full-page-loading-spinner">
          <v-progress-circular indeterminate color="secondary" :size="80"></v-progress-circular>
        </div>
        <div v-else class="d-flex flex-column dialog-content">
          <SubmitBtn class="confirmation-btn" @submit-click="handleRemoveUser"> Yes </SubmitBtn>
          <SubmitBtn class="confirmation-btn" @submit-click="closeConfirmation"> No </SubmitBtn>
        </div>
      </template>
    </MandellsDialog>
    <MandellsSnackbar :value="snackbarOpen" :variety="resultVariety" :text="resultText" @close="handleSnackbarClose" />
  </div>
</template>

<script>
import { debounce } from "vue-debounce"
import { DEBOUNCE_INTERVAL } from "@/constants/debounceInterval"
import MandellsSnackbar from "@/components/shared/MandellsSnackbar.vue"
import AddUsersBtn from "../components/shared/AddUsersBtn.vue"
import MandellsMenu from "../components/shared/MandellsMenu.vue"
import MandellsSearch from "../components/shared/MandellsSearch.vue"
import UsersTable from "../components/UsersTable/UsersTable.vue"
import { getAllClinicUsers, removeClinicUser } from "../services/userInformationServices"
import { getAllClinics } from "../services/clinicServices"
import MandellsDialog from "../components/shared/MandellsDialog.vue"
import SubmitBtn from "../components/shared/SubmitBtn.vue"

export default {
  components: {
    UsersTable,
    AddUsersBtn,
    MandellsSearch,
    MandellsMenu,
    MandellsDialog,
    SubmitBtn,
    MandellsSnackbar,
  },
  props: {
    currentUser: {
      type: Object,
      default: null,
    },
    mandellsAdmin: {
      type: Boolean,
      default: false,
    },
    clinicAdmin: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    confirmationOpen: false,
    selectedUser: {},
    clinics: null,
    clinicNames: ["All"],
    clinicUsers: [],
    selectedClinic: null,
    loading: true,
    dialogLoading: false,
    search: null,
    headers: [
      { text: "Name", value: "name" },
      { text: "Suffix", value: "suffix" },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone" },
    ],
    errorOccurred: false,
    resultVariety: null,
    resultText: null,
    snackbarOpen: false,
    pageSize: 15,
    pageNumber: 1,
    paginated: true,
    hasNextPage: null,
    totalUsersCount: 0,
    sortBy: "lastName",
    sortDirection: "ASC",
    observer: null,
    observedElement: null,
    addClinicUsersPosition: null,
    addingMoreUsers: false,
  }),
  created() {
    this.debouncedHandler = debounce(async event => {
      await this.searchUsers(event)
    }, DEBOUNCE_INTERVAL)
  },
  async mounted() {
    if (this.mandellsAdmin) {
      await this.showMandellsAdmin()
    } else if (this.clinicAdmin) {
      await this.showClinicAdmin()
    } else {
      this.$router.push("/patients")
    }
  },
  methods: {
    closeConfirmation() {
      this.confirmationOpen = false
      this.selectedUser = {}
    },

    handleRouteClick() {
      this.$router.push({ path: "/users/add-user" })
    },

    handleEditUser(value) {
      this.$router.push({ name: "EditUser", params: { userId: value.id } })
    },
    handleRemoveClick(value) {
      this.selectedUser = value
      this.confirmationOpen = true
    },

    async handleRemoveUser() {
      this.dialogLoading = true
      try {
        await removeClinicUser(this.selectedUser.id)
        this.$emit("submission-result", {
          type: "success",
          message: `${this.selectedUser.firstName} ${this.selectedUser.lastName} removed!`,
        })
        this.selectedUser = {}
        this.closeConfirmation()
        this.dialogLoading = false
        this.loading = true
        this.clinicUsers = await this.fetchClinicUsers()
        this.loading = false
      } catch (error) {
        this.dialogLoading = false
        this.errorOccurred = true
        this.resultVariety = "error"
        this.resultText = error.message
        this.snackbarOpen = true
      }
    },

    handleSnackbarClose() {
      this.snackbarOpen = false
    },

    async fetchClinicUsers() {
      if (this.mandellsAdmin && this.selectedClinic !== null) {
        const response = await getAllClinicUsers(
          this.selectedClinic.id,
          this.pageSize,
          this.pageNumber,
          this.paginated,
          this.sortBy,
          this.sortDirection,
          this.search
        )
        return this.setClinicUserResponse(response)
      }
      const response = await getAllClinicUsers(
        null,
        this.pageSize,
        this.pageNumber,
        this.paginated,
        this.sortBy,
        this.sortDirection,
        this.search
      )
      return this.setClinicUserResponse(response)
    },

    setClinicUserResponse(value) {
      this.hasNextPage = value.hasNextPage
      this.totalUsersCount = value.total
      if (this.hasNextPage) {
        this.addClinicUsersPosition = this.pageSize * this.pageNumber - 5
      }
      return this.combineNameProperties(value.items)
    },

    combineNameProperties(arr) {
      const updatedArr = arr.map(arr => {
        if (arr.middleInitial) {
          return {
            ...arr,
            name: `${arr.lastName}, ${arr.firstName}, ${arr.middleInitial}`,
          }
        }
        return {
          ...arr,
          name: `${arr.lastName}, ${arr.firstName}`,
        }
      })
      return updatedArr
    },

    async fetchClinics() {
      if (this.clinics !== null) {
        return this.clinics
      }

      try {
        const response = await getAllClinics()
        return response
      } catch (error) {
        return error
      }
    },

    getClinicNameInfo() {
      const clinicNames = this.clinics.map(clinic => {
        return clinic.name
      })
      return clinicNames
    },

    async showMandellsAdmin() {
      this.loading = true
      this.clinics = await this.fetchClinics()
      const clinicNames = await this.getClinicNameInfo()
      clinicNames.forEach(clinicName => this.clinicNames.push(clinicName))
      this.clinicUsers = await this.fetchClinicUsers()
      this.loading = false
    },

    async showClinicAdmin() {
      this.loading = true
      this.selectedClinic = null
      this.clinicUsers = await this.fetchClinicUsers()
      this.loading = false
    },

    async handleSelected(value) {
      this.loading = true
      if (value === "All") {
        this.selectedClinic = null
        this.clinicUsers = await this.fetchClinicUsers()
        this.loading = false
      } else {
        this.selectedClinic = this.findClinicByName(value)
        this.clinicUsers = await this.fetchClinicUsers()
        this.loading = false
      }
    },

    findClinicByName(clinicName) {
      const [selectedClinic] = this.clinics.filter(clinic => clinic.name === clinicName)
      return selectedClinic
    },

    async searchUsers(value) {
      this.search = value
      this.pageNumber = 1
      this.hasNextPage = false
      await this.showInformationBasedOnUserRole()
    },

    async showInformationBasedOnUserRole() {
      if (this.mandellsAdmin) {
        await this.showMandellsAdmin()
      } else {
        await this.showClinicAdmin()
      }
    },

    async handleChangeSortDirection() {
      if (this.sortDirection === "ASC") {
        this.sortDirection = "DESC"
        this.pageNumber = 1
        this.hasNextPage = false
      } else {
        this.sortDirection = "ASC"
        this.pageNumber = 1
        this.hasNextPage = false
      }
      await this.showInformationBasedOnUserRole()
    },

    handleInitializeObserver() {
      const selectedClinicUser = this.clinicUsers[this.addClinicUsersPosition]
      const selectedClinicUserId = selectedClinicUser.id
      this.initObserver(selectedClinicUserId)
    },

    initObserver(value) {
      const trackingElement = document.getElementById(`${value}`)
      this.observeredElement = document.getElementById("users-container")

      const options = {
        root: this.observeredElement,
        threshold: [...Array(10)].map((el, index) => 0.1 * index),
      }

      const handleIntersectionEvent = async entry => {
        if (entry[0].isIntersecting && this.hasNextPage === true && this.addingMoreUsers === false) {
          this.addingMoreUsers = true
          await this.addMoreClinicUsers()
        }
      }

      this.observer = new IntersectionObserver(handleIntersectionEvent, options)
      this.observer.observe(trackingElement)
    },

    async addMoreClinicUsers() {
      this.observer.unobserve(this.observeredElement)
      this.pageNumber += 1
      const newUsers = await this.fetchClinicUsers()
      this.clinicUsers = [...this.clinicUsers, ...newUsers]
      this.addingMoreUsers = false
    },
  },
}
</script>

<style lang="scss" scoped>
.users-container {
  padding: 0 1.5rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table-wrapper {
  transition: 1s;
}
.tall {
  transform: translateY(-8rem);
  transition: 1s;
}

.top-left {
  width: 80%;
  padding-left: 0.8rem;
  .users-title {
    font-size: 2.8rem;
    font-weight: 400;
    color: var(--text);
    margin-bottom: 2rem;
  }
}
.top-right {
  width: 100%;
}

.loading-page-spinner {
  height: 60%;
}

.add-user-container {
  padding: 1rem 0 2rem 0;
  text-align: right;
  display: flex;
  justify-content: space-between;
}

.confirmation-btn:hover {
  background-color: var(--primary) !important;
  border: 0.3rem solid var(--primary) !important;
}

.confirmation-btn {
  margin-bottom: 1rem !important;
}

.dialog-content {
  width: 100%;
  margin-top: 2rem;
}

.full-page-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
}
</style>
