<template>
  <div class="user-container d-flex flex-column justify-space-between align-end">
    <MandellsDoc />
    <h6 class="body1 username">{{ firstName }} {{ lastName }}</h6>
    <SplitButton
      :edit="currentUser.role !== 'clinicUser'"
      sign-off
      @option-one="handleEditUser"
      @option-two="handleSignOff"
    />
  </div>
</template>

<script>
import SplitButton from "@/components/shared/SplitButton.vue"
import { logoutUser } from "@/services/authServices"
import MandellsDoc from "../shared/icons/MandellsDoc.vue"

export default {
  components: { MandellsDoc, SplitButton },
  props: {
    currentUser: {
      type: Object,
      default: null,
    },
    mandellsAdmin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    firstName() {
      if (this.currentUser.firstName === null && this.mandellsAdmin) {
        return "Mandell's"
      }
      return `${this.currentUser.firstName}`
    },

    lastName() {
      if (this.currentUser.lastName === null && this.mandellsAdmin) {
        return "Admin"
      }
      return `${this.currentUser.lastName}`
    },
  },
  methods: {
    async handleSignOff() {
      await logoutUser()
      this.$router.push("/sign-in")
    },
    handleEditUser() {
      this.$router.push({
        name: "EditUser",
        params: { userId: this.currentUser.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-container {
  width: 100%;
  margin: 30% 0;
  .username {
    padding: 1rem 0;
    color: var(--text);
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.1rem;
    letter-spacing: 0.011rem;
    text-align: right;
    font-weight: 400;
    text-transform: none;
  }
}
</style>
