import { ClinicUserRecord } from "@/types/ClinicUserRecord"
import { UserModel } from "@/types/UserModel"
import { InvalidCredentialsError } from "@/utils/errors"
import { makeAuthenticatedRequest } from "@/utils/makeAuthenticatedRequest"
import { getTermsUrl, getUserUrl } from "@/utils/urls"
import { addClinicId, addPaginationQuery, addSearchQuery, addSortBySortDirectionToQuery } from "./authRequestServices"

// Get all
export const getAllClinicUsers = async (
  clinicId: string,
  pageSize: string,
  pageNumber: string,
  paginated: boolean,
  sortBy: string,
  sortDirection: string,
  search?: string
) => {
  const response = await makeAuthenticatedRequest(
    `${getUserUrl()}/clinic-users?${addClinicId(clinicId)}${addSearchQuery(search)}${addPaginationQuery(
      paginated,
      pageSize,
      pageNumber
    )}${addSortBySortDirectionToQuery(sortBy, sortDirection)}`,
    "GET"
  )
  return response
}

// Get one user
export const getUserInformationById = async (userId: string) =>
  makeAuthenticatedRequest<UserModel>(`${getUserUrl()}/clinic-users/${userId}`, "GET")

// Register User
export const registerClinicUser = async (clinicUserRecord: ClinicUserRecord) => {
  await makeAuthenticatedRequest(`${getUserUrl()}/clinic-users`, "POST", clinicUserRecord)
}

// Patch User
export const patchClinicUser = async (clinicUserRecord: ClinicUserRecord, userId: string) => {
  const response = await makeAuthenticatedRequest(`${getUserUrl()}/clinic-users/${userId}`, "PATCH", clinicUserRecord)
  return response
}

export const updateTermsAndConditionsDate = async (emailAddress: string, password: string) =>
  makeAuthenticatedRequest(`${getUserUrl()}/${emailAddress}/terms-and-conditions-reviewed-date`, "POST", {
    password,
  })

export const updatePassword = async (emailAddress: string, password: string, newPassword: string) =>
  makeAuthenticatedRequest(`${getUserUrl()}/${emailAddress}/password-update`, "POST", {
    password: newPassword,
    currentPassword: password,
  })

// Remove User
export const removeClinicUser = async (clinicUserId: string) => {
  try {
    const response = await makeAuthenticatedRequest(`${getUserUrl()}/clinic-users/${clinicUserId}`, "DELETE")
    return response
  } catch (error) {
    let message = "Unknown Error"
    if (error instanceof Error) message = error.message
    throw new InvalidCredentialsError(message)
  }
}

// Get suffix options
export const getSuffixOptions = async () => {
  try {
    const response = await makeAuthenticatedRequest(`${getUserUrl()}/suffixes`, "GET")
    return response
  } catch (error) {
    let message = "Unknown Error"
    if (error instanceof Error) message = error.message
    throw new InvalidCredentialsError(message)
  }
}

// Get terms and conditions
export const getTermsAndConditions = async () => {
  try {
    const response = await makeAuthenticatedRequest(`${getTermsUrl()}`, "GET", null, false, "text")
    return response
  } catch (error) {
    let message = "Unknown Error"
    if (error instanceof Error) message = error.message
    throw new InvalidCredentialsError(message)
  }
}
