<template>
  <div>
    <UserInformationContent
      :mandells-admin="mandellsAdmin"
      :clinic-admin="clinicAdmin"
      :current-user="currentUser"
      :user="user"
      :error-occurred="errorOccurred"
      :submitting="addingUser"
      @cancel-click="handleCancel"
      @submit="handleRegisterUser"
    >
      <template #title> Add User </template>
      <template #submit-label> Add User </template>
    </UserInformationContent>
    <MandellsSnackbar :value="snackbarOpen" :variety="resultVariety" :text="resultText" @close="handleSnackbarClose" />
  </div>
</template>

<script>
import MandellsSnackbar from "@/components/shared/MandellsSnackbar.vue"
import { registerClinicUser } from "@/services/userInformationServices"
import UserInformationContent from "../components/shared/UserInformationContent.vue"

export default {
  components: { UserInformationContent, MandellsSnackbar },
  props: {
    currentUser: {
      type: Object,
      default: () => ({}),
    },
    mandellsAdmin: {
      type: Boolean,
      default: false,
    },
    clinicAdmin: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    errorOccurred: false,
    errorMessage: null,
    resultVariety: null,
    resultText: null,
    snackbarOpen: false,
    addingUser: false,
  }),

  methods: {
    handleCancel() {
      this.$router.push({ path: "/users" })
    },

    async handleRegisterUser(value) {
      try {
        this.addingUser = true
        await registerClinicUser(value.formData)
        this.$emit("submission-result", {
          type: "success",
          message: `${value.formData.firstName} ${value.formData.lastName} created!`,
        })
        this.$router.push({ path: "/users" })
      } catch (error) {
        this.errorOccurred = true
        this.resultVariety = "error"
        this.resultText = "An error occurred while adding the user"
        this.snackbarOpen = true
      }
      this.addingUser = false
    },

    handleSnackbarClose() {
      this.snackbarOpen = false
      this.errorOccurred = false
    },
  },
}
</script>
