<template>
  <div class="sign-up-container">
    <div class="sign-up-background"></div>
    <div class="sunflower-1"></div>
    <div class="sunflower-2"></div>
    <div class="sign-up-content">
      <div class="sign-up-logo">
        <MandellsLogoLarge />
      </div>
      <UserSignUp :error-occurred="errorOccurred" @submission-result="handleSubmissionResult" />
    </div>
    <MandellsSnackbar :value="snackbarOpen" :variety="resultVariety" :text="resultText" @close="handleSnackbarClose" />
  </div>
</template>

<script>
import MandellsSnackbar from "@/components/shared/MandellsSnackbar.vue"
import UserSignUp from "@/components/UserSignUp.vue"
import MandellsLogoLarge from "../components/shared/icons/MandellsLogoLarge.vue"

export default {
  components: { MandellsSnackbar, MandellsLogoLarge, UserSignUp },
  data: () => ({
    resultVariety: null,
    resultText: null,
    snackbarOpen: false,
    errorOccurred: false,
  }),
  methods: {
    handleSubmissionResult(value) {
      this.resultVariety = value.type
      this.resultText = value.message
      this.snackbarOpen = true
      this.errorOccurred = true
    },
    handleSnackbarClose() {
      this.snackbarOpen = false
      this.errorOccurred = false
    },
  },
}
</script>

<style scoped lang="scss">
.sign-up-container {
  border: 0.1rem solid rgb(212, 214, 212);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  padding: 9.16% 6.11%;
}

.sign-up-background {
  background: url("../assets/baby-feet-background.jpg") no-repeat, center;
  background-size: cover;
  opacity: 20;
  height: 100%;
  width: 106.69%;
  position: fixed;
  top: -1.27%;
}

.sunflower-1 {
  background: url("../assets/sunflowers-top.png") no-repeat, center;
  background-size: contain;
  height: 34.96%;
  width: 26.68%;
  position: fixed;
  top: 0;
  left: 0;
}

.sunflower-2 {
  background: url("../assets/sunflowers-top.png") no-repeat, center;
  background-size: contain;
  height: 34.96%;
  width: 26.68%;
  position: fixed;
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
  z-index: 2;
}

.sign-up-content {
  width: 100%;
  max-width: 126.2rem;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-up-logo {
  width: 33.6%;
  height: 40.41%;
  min-height: 30rem;
  margin-right: 9.26%;
}

@media (max-width: 1180px) {
  .sign-up-content {
    margin-top: 24rem;
    flex-direction: column;
  }

  .sign-up-logo {
    margin-right: 0;
    width: 30rem;
    min-height: 20rem;
  }
}

@media (max-width: 800px) {
  .sunflower-2 {
    z-index: 1;
  }
}

@media (max-width: 700px) {
  .sign-up-content .container {
    min-width: auto;
    width: 100%;
  }
}
</style>
