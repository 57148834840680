<template>
  <v-dialog
    :value="open"
    :max-width="dialogMaxWidth"
    :persistent="persistent"
    @click:outside="closeModal()"
    @keydown.enter="handleEnterPressed()"
    @keydown.esc="closeModal()"
  >
    <v-card class="dialog-card" color="#deedf6">
      <v-card-title>
        <span class="dialog-title" :style="`color: ${titleColor}`"
          ><slot name="dialog-title"></slot> <span v-if="title">{{ title }}</span></span
        >
        <v-btn v-if="!persistent" class="close-btn" icon :disabled="disableCloseButton" @click="closeModal()"
          ><v-icon class="close-icon" color="secondary">highlight_off</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text class="d-flex justify-center" style="padding: 0">
        <h5 class="dialog-subtitle">
          {{ dialogSubtitle }}
        </h5>
      </v-card-text>

      <transition name="error">
        <v-card-text v-if="errorOccurred" class="dialog-error-text">{{ errorMessage }}</v-card-text>
      </transition>

      <v-card-actions>
        <slot name="content"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    dialogSubtitle: {
      type: String,
      default: "",
    },
    dialogMaxWidth: {
      type: [Number, String],
      default: 500,
    },
    open: {
      type: Boolean,
      default: false,
    },
    disableCloseButton: {
      type: Boolean,
      default: false,
    },
    errorOccurred: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    titleColor: {
      type: String,
      default: "var(--text);",
    },
    persistent: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    closeModal() {
      if (!this.persistent) {
        this.$emit("dialog-closed")
      }
    },
    handleEnterPressed() {
      this.$emit("enter-pressed")
    },
  },
}
</script>

<style lang="scss">
// This CSS cannot be scoped because the dialog is mounted near the root of the app -- v-deep will not work

.v-dialog {
  margin: 0 !important;
  box-shadow: none !important;
  border-radius: 3rem !important;
  box-shadow: 0.1rem 0.1rem 0.8rem 0 rgba(0, 0, 0, 0.5) !important;

  .v-sheet,
  .v-card {
    box-shadow: none !important;
    position: relative;
  }

  .v-card__title {
    border-radius: 0 !important;
    display: flex;
    text-align: left;
    padding: 0 !important;

    .dialog-title {
      font-size: 2.4rem;
      line-height: 3.4rem;
      letter-spacing: 0.016rem;
      font-weight: 400;
      width: 100%;
      text-align: center;
    }

    .v-btn {
      position: absolute;
      right: 2.5rem;
      top: 2.5rem;

      .v-icon {
        font-size: 4rem;
      }
    }
  }

  .v-card__actions {
    padding: 0 !important;
  }

  .action-buttons-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 1rem;
  }

  .dialog-error-text {
    color: red !important;
    font-size: 1.5rem;
    text-align: center;
  }

  .close-icon {
    width: 2rem;
  }
}

.dialog-card {
  border-radius: 3rem !important;
  padding: 5.5rem 4rem;
  color: var(--text);

  .dialog-subtitle {
    color: var(--text);
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 3.4rem;
    letter-spacing: 0.016rem;
    margin-top: 1.8rem;

    .subtitle-content {
      color: var(--secondary);
    }
  }
}

.error-enter-active,
.error-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.error-enter,
.error-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
</style>
