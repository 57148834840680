<template>
  <div>
    <UserInformationContent
      :mandells-admin="mandellsAdmin"
      :clinic-admin="clinicAdmin"
      :current-user="currentUser"
      :user="user"
      :error-occurred="errorOccurred"
      :submitting="submitting"
      @cancel-click="handleCancel"
      @submit="handleUserUpdate"
    >
      <template #title> Edit User </template>
      <template #submit-label> Save </template>
    </UserInformationContent>
    <MandellsSnackbar :value="snackbarOpen" :variety="resultVariety" :text="resultText" @close="handleSnackbarClose" />
  </div>
</template>

<script>
import MandellsSnackbar from "@/components/shared/MandellsSnackbar.vue"
import { patchClinicUser } from "@/services/userInformationServices"
import UserInformationContent from "../components/shared/UserInformationContent.vue"

export default {
  components: { UserInformationContent, MandellsSnackbar },
  props: {
    mandellsAdmin: {
      type: Boolean,
      default: false,
    },
    clinicAdmin: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Boolean,
      default: false,
    },
    currentUser: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    errorOccurred: false,
    errorMessage: null,
    resultVariety: null,
    resultText: null,
    snackbarOpen: false,
    submitting: false,
  }),
  methods: {
    handleCancel() {
      this.$router.push({ path: "/users" })
    },

    async handleUserUpdate(value) {
      this.submitting = true

      try {
        await patchClinicUser(value.formData, value.id)
        if (value.isEditingSelf) {
          this.$emit("submission-result-self", {
            type: "success",
            message: `${value.formData.firstName} ${value.formData.lastName} Updated!`,
          })
        } else {
          this.$emit("submission-result", {
            type: "success",
            message: `${value.formData.firstName} ${value.formData.lastName} Updated!`,
          })
        }

        this.$router.push({ path: "/users" })
      } catch (error) {
        let message = "Unknown Error"
        if (error instanceof Error) message = error.message
        this.errorOccurred = true
        this.resultVariety = "error"
        this.resultText = message
        this.snackbarOpen = true
      }

      this.submitting = false
    },

    handleSnackbarClose() {
      this.snackbarOpen = false
      this.errorOccurred = false
    },
  },
}
</script>
