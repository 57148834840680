import { logoutUser } from "@/services/authServices"
import axios, { AxiosRequestConfig, Method } from "axios"
import firebase from "firebase/app"
import "firebase/auth"

/* eslint-disable default-param-last */
export const makeAuthenticatedRequest = async <ResponseType>(
  url: string,
  method: Method = "GET",
  body?: any,
  logError = true,
  responseType: AxiosRequestConfig["responseType"] = "json",
  redirectIfNotAuthenticated = true
): Promise<ResponseType> => {
  try {
    const token = await firebase.auth().currentUser?.getIdToken(true)

    const response = await axios({
      method,
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: body,
      responseType,
    })

    return response.data
  } catch (error) {
    if (redirectIfNotAuthenticated && axios.isAxiosError(error) && error.response!.status === 401) {
      await logoutUser()
    }
    // TODO: add toast option
    if (logError && error instanceof Error) {
      // eslint-disable-next-line no-console
      console.error(`An error occured while fetching data for url: '${url}', ${error.message}`)
    }

    return Promise.reject(error)
  }
}
