<template>
  <div :class="expand ? 'search-container search-transition' : 'search-container'">
    <v-text-field
      :value="value"
      type="text"
      full-width
      color="secondary"
      prepend-inner-icon
      class="input"
      :placeholder="placeholder"
      @input="handleInput($event)"
    >
      <template #prepend-inner>
        <MandellsSearchIcon :class="expand ? 'icon icon-transition' : 'icon'" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
import MandellsSearchIcon from "./icons/MandellsSearchIcon.vue"

export default {
  components: { MandellsSearchIcon },
  props: {
    expand: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleInput(value) {
      this.$emit("input", value)
    },
  },
}
</script>

<style lang="scss" scoped>
.search-container {
  height: 6.4rem;
  width: 98%;
  margin: 0 auto 1rem auto;
  transition: 0.5s;
  transition-delay: 0.5s;
}

.search-transition {
  opacity: 0;
  transition: 0.5s;
}

.input {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 2rem;
  line-height: 2.6rem;
  letter-spacing: 0.013rem;
  color: var(--text);
  transition: 0.5s;

  ::placeholder {
    color: var(--text) !important;
    opacity: 1;
  }
}

.icon {
  opacity: 1;
  transition: 0.5s;
}
.icon-transition {
  opacity: 0;
  transition: 0.5s;
}

::v-deep {
  .theme--light,
  .v-text-field,
  .v-input__control,
  .v-input__slot:before {
    border-color: var(--text) !important;
  }
  #Dashboard-All-Patients {
    transition: 0.3s;
  }
  .v-input--is-focused {
    .v-input__prepend-inner {
      svg {
        #Dashboard-All-Patients {
          transition: 0.3s;
          fill: var(--secondary) !important;
        }
      }
    }
  }
  .text-field__slot,
  .theme--light,
  .v-input {
    input {
      color: var(--text) !important;
      margin-left: 2rem;
    }
  }
}
</style>
