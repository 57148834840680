import { makeAuthenticatedRequest } from "@/utils/makeAuthenticatedRequest"
import { getPatientsUrl } from "@/utils/urls"

export const getPatientById = async (patientId: string) =>
  makeAuthenticatedRequest(`${getPatientsUrl()}/${patientId}`, "GET")

export const getPatientMedicationOrders = async (patientId: string, clinicId: string) =>
  makeAuthenticatedRequest(`${getPatientsUrl()}/${patientId}/medication-orders?clinicId=${clinicId}`, "GET")

export const getPatientMedicationOrderTrackingInformation = async (
  patientId: string,
  scriptNumber: string,
  fillNumber: string
) => {
  const response = await makeAuthenticatedRequest(
    `${getPatientsUrl()}/${patientId}/prescriptions/${scriptNumber}/orders/${fillNumber}/tracking-information`,
    "GET"
  )
  return response
}
