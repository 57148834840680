<template>
  <div class="stepper-container">
    <v-stepper :value="step" alt-labels class="stepper" color="secondary">
      <v-stepper-header>
        <slot name="content"></slot>
      </v-stepper-header>
    </v-stepper>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
}
</script>

<style lang="scss" scoped>
.stepper-container {
  border: 0px !important;
  width: 100%;
}
.stepper {
  background-color: transparent !important;
  box-shadow: none;

  .step {
    color: var(--text);

    span {
      font-size: 1.8rem;
      line-height: 0.26rem;
      letter-spacing: 0.012rem;
    }
  }

  &::v-deep {
    .tracking-header {
      text-align: center;
    }
    .v-stepper__step__step {
      height: 2.2rem;
      width: 2.2rem;
      min-width: 0px;

      .v-icon {
        font-size: 2rem;
        font-weight: 800;
      }
    }
  }
}
</style>
