<template>
  <div class="container">
    <div class="card-content">
      <LoginHeader />
      <transition name="forgot-password-transition" mode="out-in">
        <v-form
          v-if="forgotPasswordVisible"
          key="forgot-password"
          class="login-form forgot-password-form d-flex flex-column"
        >
          <div class="forgot-password-title">Forgot password?</div>
          <MandellsTextInput
            v-model="forgotPasswordEmail"
            :type="'email'"
            :disabled="forgotPasswordLoading"
            :error="forgotPasswordEmailError"
            :error-messages="forgotPasswordEmailErrorMessages"
            @enter-pressed="handleForgotPassword"
            >Email</MandellsTextInput
          >
          <SubmitBtn :loading="forgotPasswordLoading" :outlined="true" @submit-click="handleForgotPassword"
            >Send password recovery email</SubmitBtn
          >
          <v-btn plain class="login-link-btn" @click="handleBackToLoginClicked"> Back to login </v-btn>
        </v-form>
        <v-form v-else key="log-in" action="" class="login-form d-flex flex-column">
          <div>
            <MandellsTextInput
              v-model="formData.email"
              :type="'email'"
              :error="errorOccurred"
              @enter-pressed="handleLogin()"
              >Email</MandellsTextInput
            >
            <MandellsTextInput
              v-model="formData.password"
              :type="visible ? 'text' : 'password'"
              :error="errorOccurred"
              :append-icon="visible ? 'visibility' : 'visibility_off'"
              style="margin-bottom: 0 !important"
              @append="toggleVisible"
              @enter-pressed="handleLogin()"
              >Password</MandellsTextInput
            >
          </div>
          <div class="forgot-password-container d-flex justify-end">
            <v-btn
              plain
              :loading="forgotPasswordLoading"
              class="login-link-btn"
              :style="errorOccurred ? 'color: var(--error)' : ''"
              @click="handleForgotPasswordClicked"
            >
              Forgot Password?
            </v-btn>
          </div>
          <SubmitBtn :loading="loggingIn" :outlined="true" @submit-click="handleLogin()">Sign In</SubmitBtn>
          <div class="terms-conditions-btn-wrapper">
            <span>View the</span>
            <a plain class="terms-conditions-btn" @click="openTermsConditions" @keydown.enter="openTermsConditions">
              Terms and Conditions
            </a>
          </div>
          <TermsConditions
            v-if="termsAndConditionsDialogOpen"
            :open="termsAndConditionsDialogOpen"
            :email="formData.email"
            :password="formData.password"
            :agreement-required="userMustAgreeToTermsAndConditions"
            @terms-agreed-to="handleTermsAgreedTo"
            @closed="handleTermsConditionsClosed"
          />
          <UpdatePasswordDialog
            v-if="passwordDialogOpen"
            :open="passwordDialogOpen"
            :email="formData.email"
            :password="formData.password"
            @password-updated="password => handlePasswordUpdated(password)"
          />
          <div class="error-message-container">
            <transition name="error-transition">
              <h4 v-show="errorOccurred" class="error-message">
                {{ errorMessage }}
              </h4>
            </transition>
          </div>
        </v-form>
      </transition>
      <p class="copyright cap"><CopyrightText /></p>
    </div>
  </div>
</template>

<script>
import { forgotPassword, login } from "@/services/authRequestServices"
import { getCurrentAuthUserId } from "@/services/authServices"
import { getUserInformationById } from "@/services/userInformationServices"
import { InvalidCredentialsError } from "@/utils/errors"
import MandellsTextInput from "../shared/MandellsTextInput.vue"
import SubmitBtn from "../shared/SubmitBtn.vue"
import CopyrightText from "../shared/CopyrightText.vue"
import LoginHeader from "./LoginHeader.vue"
import TermsConditions from "./TermsConditions.vue"
import UpdatePasswordDialog from "./UpdatePasswordDialog.vue"

const EMAIL_REGEX = /.+@.+\..+/

export default {
  components: { LoginHeader, MandellsTextInput, SubmitBtn, TermsConditions, CopyrightText, UpdatePasswordDialog },
  props: {
    errorOccurred: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    visible: false,
    errorMessage: null,
    loggingIn: false,
    updatingUser: false,
    userInformation: null,
    formData: {
      email: null,
      password: null,
      terms: null,
    },
    forgotPasswordEmail: "",
    forgotPasswordEmailError: false,
    snackbarOpen: false,
    resultVariety: null,
    resultText: null,
    isUserTermsConditionsAgreed: false,
    termsAndConditionsDialogOpen: false,
    passwordDialogOpen: false,
    forgotPasswordVisible: false,
    forgotPasswordLoading: false,
    userMustAgreeToTermsAndConditions: false,
    userMustUpdatePassword: false,
    userTimedOut: false,
  }),
  computed: {
    forgotPasswordEmailErrorMessages() {
      return this.forgotPasswordEmailError ? ["Please enter a valid email address"] : []
    },
  },
  methods: {
    toggleVisible() {
      this.visible = !this.visible
    },
    openTermsConditions() {
      this.termsAndConditionsDialogOpen = true
    },
    handleLoginError(error) {
      if (error.userMustAgreeToTermsAndConditions) {
        this.userMustAgreeToTermsAndConditions = true
        this.termsAndConditionsDialogOpen = true
        return
      }

      if (error.userMustUpdatePassword) {
        this.userMustUpdatePassword = true
        this.passwordDialogOpen = true
        return
      }

      if (error.userTimedOut) {
        this.userTimedOut = true
        this.$emit("submission-result", {
          type: "error",
          message: "Too many login attempts -- please try again in 15 minutes",
        })
        return
      }

      if (!error.userMustAgreeToTermsAndConditions && !error.userMustUpdatePassword) {
        this.$emit("submission-result", { type: "error", message: error.message })
      }
    },
    async handleLogin(password) {
      this.loggingIn = true

      const passwordForLogin = password || this.formData.password

      try {
        await login(passwordForLogin, this.formData.email)
        this.userInformation = await this.fetchUserInformation()
        this.userRouterPushOnRole(this.userInformation.role)
      } catch (error) {
        this.handleLoginError(error)
      }
      this.loggingIn = false
    },
    userRouterPushOnRole(value) {
      if (value === "mandellsAdmin" || value === "clinicAdmin") {
        this.$router.push({ path: "/users" })
      } else {
        this.$router.replace({ path: "/patients" })
      }
    },
    async fetchUserInformation() {
      try {
        const userId = getCurrentAuthUserId()
        const response = await getUserInformationById(userId)
        return response
      } catch (error) {
        throw new InvalidCredentialsError("User not found")
      }
    },
    hasUserAgreedToTermsConditions(currentUser) {
      const todaysDate = new Date()
      const currentTermsConditionsDate = new Date(currentUser.termsAndConditionsLastAgreedTo)
      currentTermsConditionsDate.setDate(currentTermsConditionsDate.getDate() + 90)
      const timeDifference = currentTermsConditionsDate.getTime() - todaysDate.getTime()
      const numDaysBtwn = timeDifference / (1000 * 60 * 60 * 24)
      if (numDaysBtwn > 0) {
        return true
      }
      return false
    },
    handleTermsAgreedTo() {
      this.termsAndConditionsDialogOpen = false
      this.userMustAgreeToTermsAndConditions = false

      if (this.userMustUpdatePassword) {
        this.passwordDialogOpen = true
      } else {
        this.handleLogin()
      }
    },
    handleTermsConditionsClosed() {
      this.termsAndConditionsDialogOpen = false
    },
    async handlePasswordUpdated(updatedPassword) {
      this.loggingIn = true
      this.userMustUpdatePassword = false

      this.handleLogin(updatedPassword)
    },
    handleForgotPasswordClicked() {
      this.forgotPasswordVisible = true
    },
    handleBackToLoginClicked() {
      this.forgotPasswordVisible = false
    },
    async handleForgotPassword() {
      this.forgotPasswordEmailError = false

      if (this.forgotPasswordEmail === "") {
        this.$emit("submission-result", { type: "error", message: "No email provided" })
      } else if (!EMAIL_REGEX.test(this.forgotPasswordEmail)) {
        this.forgotPasswordEmailError = true
      } else {
        await this.sendForgotPasswordEmail()
      }

      this.forgotPasswordLoading = false
    },
    async sendForgotPasswordEmail() {
      this.forgotPasswordLoading = true

      try {
        await forgotPassword(this.forgotPasswordEmail)

        this.$emit("submission-result", { type: "success", message: `Password reset email sent` })
        this.forgotPasswordVisible = false
        this.forgotPasswordEmail = ""
      } catch (error) {
        this.$emit("submission-result", { type: "error", message: `${error.message}` })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  min-height: 72rem;
  max-height: 76rem;
  width: 56.96%;
  min-width: 66rem;
  max-width: 72rem;
  background-color: var(--primary);
  border: 6px solid var(--primary-light);
  border-radius: 3rem;
  z-index: 1;
}

.card-content {
  padding: 7rem 0 5.26% 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.login-form {
  padding: 3.6rem 13.98% 4rem 13.98%;
  height: 81.94%;
  bottom: 0;
  width: 100%;

  &.forgot-password-form {
    .forgot-password-title {
      text-align: center;
      font-size: 1.6rem;
      color: var(--secondary);
    }
  }

  .login-link-btn {
    color: var(--text);
    text-transform: none;
    text-decoration: none;
    font-size: 1.4rem;
    line-height: 1.9rem;
    letter-spacing: 0.009rem;
    padding: 0 !important;
    height: 2.5rem;
    margin-top: 1rem;
  }

  .error-message-container {
    position: relative;
    bottom: 15rem;
    left: 0;
    display: flex;
    width: 100%;
    z-index: 3;

    .error-message {
      width: 100%;
      position: absolute;
      text-align: center;
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: 0.013rem;
      color: var(--error);
    }
  }

  .forgot-password-container {
    width: 100%;
    margin-bottom: 6rem;
  }
}

.terms-checkbox {
  padding: 0 2rem;
}

.copyright {
  text-align: center;
  color: var(--text);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  position: absolute;
  bottom: 3rem;
  width: 100%;
}

.forgot-password-transition-enter-active,
.forgot-password-transition-leave-active {
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.forgot-password-transition-enter,
.forgot-password-transition-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}

.terms-conditions-btn-wrapper {
  font-size: 1.3rem;
  color: var(--text);
  display: flex;
  justify-content: center;
  .terms-conditions-btn {
    color: var(--text);
    padding-left: 0.3rem;
    text-decoration: underline;
  }
}

@media (max-width: 700px) {
  .forgot-password-title {
    margin-bottom: 2rem;
  }
}

@media (max-width: 500px) {
  .login-form {
    height: 100%;
    margin-bottom: 2rem;
  }

  .login-link-btn {
    font-size: 1.2rem;
  }
}
</style>
