var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('v-autocomplete',{staticClass:"selector",attrs:{"id":_vm.id,"items":_vm.items,"attach":"","disabled":_vm.disabled,"menu-props":{
    attach: true,
    offsetY: true,
    maxHeight: 100,
  },"color":"secondary"},on:{"change":_vm.handleSelect},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"select-label"},[_vm._t("label")],2)]},proxy:true},{key:"item",fn:function(ref){
  var item = ref.item;
  var attrs = ref.attrs;
  var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"color":"secondary"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',{staticClass:"select-item"},[(_vm.selected === item)?_c('v-icon',{staticClass:"check"},[_vm._v("check")]):_vm._e(),_c('v-list-item-title',{class:attrs['aria-selected'],style:(("padding-left: " + _vm.contentPadding + "rem")),attrs:{"id":attrs['aria-labelledby']},domProps:{"textContent":_vm._s(item.text || item)}})],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('p',[_vm._v("test")])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }