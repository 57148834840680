<template>
  <div class="container">
    <div class="card-content">
      <div v-if="tokenError" class="invalid-url-text-container">
        <div class="invalid-url-text">{{ tokenErrorMessage }}</div>
      </div>
      <div v-else-if="isLoading" class="full-page-loading-spinner">
        <v-progress-circular indeterminate color="secondary" :size="100"></v-progress-circular>
      </div>
      <div v-else>
        <LoginHeader />
        <v-form action="" class="login-form d-flex flex-column">
          <div>
            <MandellsTextInput
              v-model="password"
              :type="isPasswordVisible(visible, true)"
              :error="errorOccurred"
              :append-icon="isPasswordVisible(visible, false)"
              @append="toggleVisible"
              @enter-pressed="handleSetPassword()"
            >
              Password
            </MandellsTextInput>
            <MandellsTextInput
              v-model="confirmPassword"
              :type="isPasswordVisible(confirmVisible, true)"
              :error="errorOccurred"
              :append-icon="isPasswordVisible(confirmVisible, false)"
              style="margin-bottom: 0 !important"
              @append="toggleConfirmVisible"
              @enter-pressed="handleSetPassword()"
            >
              Confirm Password
            </MandellsTextInput>
          </div>
          <div class="password-info-container">
            <div class="label">
              Password must be at least six characters and include a lowercase letter, uppercase letter, number, and
              special character
            </div>
          </div>

          <SubmitBtn :loading="savingPassword" :outlined="true" @submit-click="handleSetPassword">
            Set Password
          </SubmitBtn>
          <TermsConditions
            v-if="termsAndConditionsDialogOpen"
            :open="termsAndConditionsDialogOpen"
            :email="userEmail"
            :password="password"
            :agreement-required="true"
            :user-sign-up="true"
            @terms-agreed-to="handleCloseTermsAndConditionsDialog"
          />
          <div class="error-message-container">
            <transition name="error-transition">
              <h4 v-show="errorOccurred" class="error-message">
                {{ errorMessage }}
              </h4>
            </transition>
          </div>
          <p class="copyright cap"><CopyrightText /></p>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentAuthUser, login } from "@/services/authRequestServices"
import { getUserInformationById } from "@/services/userInformationServices"
import { makeAuthenticatedRequest } from "@/utils/makeAuthenticatedRequest"
import { getUserUrl } from "@/utils/urls"
import MandellsTextInput from "./shared/MandellsTextInput.vue"
import SubmitBtn from "./shared/SubmitBtn.vue"
import CopyrightText from "./shared/CopyrightText.vue"
import LoginHeader from "./UserLogin/LoginHeader.vue"
import TermsConditions from "./UserLogin/TermsConditions.vue"

const PASSWORDS_DO_NOT_MATCH_ERROR_MESSAGE = "Passwords do not match"
const PASSWORD_TOO_SHORT_ERROR_MESSAGE = "Your password must be at least 6 characters long"
const ERROR_OCCURRED_SETTING_PASSWORD_MESSAGE = "An error occurred setting your password"
const ERROR_OCCURRED_LOGGING_IN = "An error logging in"
const PASSWORD_MISSING_REQ_CHARS_ERROR_MESSAGE = "Missing required characters."

export default {
  components: { LoginHeader, MandellsTextInput, SubmitBtn, TermsConditions, CopyrightText },
  props: {
    errorOccurred: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    errorMessage: null,
    tokenError: null,
    visible: false,
    confirmVisible: false,
    password: "",
    confirmPassword: "",
    isLoading: true,
    savingPassword: false,
    passwordsDoNotMatch: false,
    passwordIsTooShort: false,
    passwordMissingRequiredCharacters: false,
    snackbarOpen: false,
    resultVariety: null,
    resultText: null,
    userEmail: null,
    userToken: null,
    termsAndConditionsDialogOpen: false,
    passwordContainsLowercase: false,
    passwordContainsUppercase: false,
    passwordContainsNumber: false,
    passwordContainsSpecialChar: false,
  }),
  computed: {
    formErrorMessage() {
      if (this.passwordsDoNotMatch) {
        return PASSWORDS_DO_NOT_MATCH_ERROR_MESSAGE
      }

      if (this.passwordIsTooShort) {
        return PASSWORD_TOO_SHORT_ERROR_MESSAGE
      }

      if (this.passwordMissingRequiredCharacters) {
        return PASSWORD_MISSING_REQ_CHARS_ERROR_MESSAGE
      }

      if (this.errorOccurredSettingPassword) {
        return ERROR_OCCURRED_SETTING_PASSWORD_MESSAGE
      }

      if (this.errorOccurredLoggingIn) {
        return ERROR_OCCURRED_LOGGING_IN
      }

      return ""
    },
  },
  created() {
    if (!getCurrentAuthUser()) {
      this.verifySignupTokenIfSupplied()
    } else {
      this.$router.push({ name: "SignIn" })
    }
  },
  mounted() {
    this.userEmail = decodeURIComponent(this.$route.query.email)
  },
  methods: {
    toggleVisible() {
      this.visible = !this.visible
    },
    toggleConfirmVisible() {
      this.confirmVisible = !this.confirmVisible
    },

    getUserIdAndToken() {
      const { userId, token } = this.$route.query
      return { userId, token }
    },

    async verifySignupTokenIfSupplied() {
      const { userId, token } = this.getUserIdAndToken()
      if (!userId || !token) {
        this.tokenError = true
        this.tokenErrorMessage = "Invalid sign-up link"
        this.isLoading = false
      } else {
        this.isLoading = false
      }
    },

    checkForRequiredCharacters(string) {
      const specialCharacters = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).+$/
      return specialCharacters.test(string)
    },

    async handleSetPassword() {
      if (this.password !== this.confirmPassword) {
        this.passwordsDoNotMatch = true
        this.passwordIsTooShort = false
        this.passwordMissingRequiredCharacters = false
        this.$emit("submission-result", { type: "error", message: this.formErrorMessage })
      } else if (this.password.length < 6) {
        this.passwordIsTooShort = true
        this.passwordsDoNotMatch = false
        this.passwordMissingRequiredCharacters = false
        this.$emit("submission-result", { type: "error", message: this.formErrorMessage })
      } else if (!this.checkForRequiredCharacters(this.password)) {
        this.passwordMissingRequiredCharacters = true
        this.passwordsDoNotMatch = false
        this.passwordIsTooShort = false
        this.$emit("submission-result", { type: "error", message: this.formErrorMessage })
      } else {
        this.passwordsDoNotMatch = false
        this.passwordIsTooShort = false
        this.passwordMissingRequiredCharacters = false

        this.savingPassword = true

        if (typeof this.$route.query.welcome !== "undefined") {
          this.termsAndConditionsDialogOpen = true
        } else {
          this.setPassword()
        }
      }
    },

    async handleCloseTermsAndConditionsDialog(value) {
      this.termsAndConditionsDialogOpen = false
      if (value) {
        await this.setPassword()
      } else {
        this.$emit("submission-result", { type: "error", message: "Terms and Conditions must be agreed to." })
        this.savingPassword = false
      }
    },

    async setPassword() {
      const { userId, token } = this.getUserIdAndToken()

      try {
        await makeAuthenticatedRequest(`${getUserUrl()}/${userId}/password`, "POST", {
          password: this.password,
          setPasswordToken: token,
        })
        await login(this.password, undefined, userId)
        this.routeAfterSettingPassword(userId)
        this.savingPassword = false
      } catch (error) {
        this.savingPassword = false
        this.errorOccurredSettingPassword = true
        this.$emit("submission-result", { type: "error", message: error.message })
      }
    },

    async routeAfterSettingPassword(userId) {
      const { role } = await getUserInformationById(userId)

      if (role === "mandellsAdmin" || role === "clinicAdmin") {
        this.$router.push({ path: "/users" })
      } else {
        this.$router.replace({ path: "/patients" })
      }
    },

    isPasswordVisible(value, isText) {
      if (value && isText) {
        return "text"
      }
      if (value) {
        return "visibility"
      }
      if (isText) {
        return "password"
      }
      return "visibility_off"
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  min-height: 72rem;
  max-height: 76rem;
  width: 56.96%;
  min-width: 66rem;
  max-width: 72rem;
  background-color: var(--primary);
  border: 6px solid var(--primary-light);
  border-radius: 3rem;
  z-index: 1;
}

.card-content {
  padding: 11.31% 0 5.26% 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  position: relative;
}
.password-info-container {
  padding: 2.5rem 0;
  width: 90%;
  margin: 0 auto;
  .label {
    color: var(--text);
    font-size: 1.4rem;
    line-height: 1.9rem;
    letter-spacing: 0.009rem;
    transition: 0.1s;
  }
}

.invalid-url-text-container {
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;

  .invalid-url-text {
    border-radius: 2rem;
    color: var(--error);
    font-size: 1.8rem;
    text-align: center;
    padding: 2rem;
  }
}

.full-page-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 100%;
  margin-top: 2rem;
}

.login-form {
  padding: 7.6rem 13.98% 4rem 13.98%;
  height: 81.94%;
  position: absolute;
  bottom: 0;
  width: 100%;

  .error-message-container {
    position: relative;
    bottom: 15rem;
    left: 0;
    display: flex;
    width: 100%;
    z-index: 3;

    .error-message {
      width: 100%;
      position: absolute;
      text-align: center;
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: 0.013rem;
      color: var(--error);
    }
  }

  .link-container {
    width: 100%;
    padding-bottom: 1rem;
    .link {
      color: var(--text);
      text-decoration: none;
      padding: auto 0;
    }
  }
}

.copyright {
  text-align: center;
  color: var(--text);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

@media (max-width: 500px) {
  .login-form {
    height: 100%;
    margin-bottom: 2rem;
  }

  .login-link-btn {
    font-size: 1.2rem;
  }

  .container {
    max-height: none;
    height: 100%;
    min-height: 100%;
  }

  .login-form {
    position: unset;
    padding-top: 0;
  }
}
</style>
