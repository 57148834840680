<template>
  <div class="card-container d-flex">
    <v-card :loading="loading" :class="expand ? 'main-card' : 'main-card short'">
      <template #progress>
        <v-progress-linear class="loading-bar" color="secondary" height="10" indeterminate></v-progress-linear>
      </template>
      <div v-if="loading"></div>
      <div v-else class="main-card-content">
        <div class="heading">
          <div class="patient-name">
            <v-card-title class="patient-lastname"> {{ lastName }}</v-card-title>
            <v-card-subtitle class="patient-firstname">
              <h5>{{ firstName }}</h5>
            </v-card-subtitle>
          </div>
          <ChangeReqBtn style="margin-top: 1rem">Change Request</ChangeReqBtn>
        </div>

        <v-expand-transition>
          <div v-show="expand" class="patient-info" height="100%" width="100%">
            <div class="info-grid">
              <div class="dob-container">
                <div class="account-text">
                  <v-card-title class="patient-info-label">Date of Birth:</v-card-title>
                  <v-card-text class="patient-info-content">{{ convertDateTime(patientData.birthDate) }}</v-card-text>
                </div>
              </div>
              <div class="address-container">
                <v-card-title class="patient-info-label">Patient Home Address:</v-card-title>
                <v-card-text class="patient-info-content inline">
                  <div class="address-line">{{ addressLineOne }},</div>
                  <div v-if="patientData.addressLineTwo != ''" class="address-line">{{ addressLineTwo }},</div>
                  <div>
                    {{ addressCity }}, {{ patientData.addressState }},
                    {{ patientData.addressZipCode }}
                  </div>
                </v-card-text>
              </div>
              <div class="phone-container">
                <div v-show="expand" class="patient-shipping-info bottom">
                  <v-card-title class="patient-info-label">Phone:</v-card-title>
                  <v-card-text class="patient-info-content">{{ phoneMask(patientData.phoneNumber) }}</v-card-text>
                </div>
              </div>
            </div>
            <div class="allergies-container">
              <div v-if="allergies !== null" class="d-flex flex-column">
                <v-card-title class="patient-info-label"> Allergies: </v-card-title>
                <v-card-text
                  v-for="allergy in allergies"
                  :key="allergy"
                  class="allergy-item patient-info-content"
                  style="width: 100%"
                >
                  {{ allergy }}
                </v-card-text>
              </div>
              <div v-else>
                <h5>No known allergies</h5>
              </div>
            </div>
          </div>
        </v-expand-transition>
      </div>
    </v-card>
  </div>
</template>

<script>
import ChangeReqBtn from "./ChangeReqBtn.vue"
import { changeToTitleCase } from "../../utils/changeToTitleCase"
import { getFormattedDate } from "../../utils/dateUtils"

export default {
  components: { ChangeReqBtn },
  props: {
    expand: {
      type: Boolean,
      default: true,
    },
    patientData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: true,
    allergies: [],
    ssn: "",
  }),
  computed: {
    lastName() {
      return changeToTitleCase(this.patientData.lastName)
    },
    firstName() {
      return changeToTitleCase(this.patientData.firstName)
    },
    addressCity() {
      return changeToTitleCase(this.patientData.addressCity)
    },
    addressLineOne() {
      return changeToTitleCase(this.patientData.addressLineOne)
    },
    addressLineTwo() {
      if (this.patientData.addressLineTwo !== "") {
        return changeToTitleCase(this.patientData.addressLineTwo)
      }
      return null
    },
  },
  created() {
    if (this.patientData) {
      this.loading = false
    }
  },
  mounted() {
    if (this.patientData.allergies.length !== 0) {
      const allergies = this.patientData.allergies.map(allergy => changeToTitleCase(allergy))
      this.allergies = allergies
    } else {
      this.allergies = null
    }
  },
  methods: {
    convertDateTime(value) {
      return getFormattedDate(value)
    },
    phoneMask(value) {
      const phone = value.replace(/[^\d]/g, "")
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
    },
  },
}
</script>

<style lang="scss" scoped>
.card-container {
  position: relative;
}

.short {
  height: 15rem !important;
  transition: 0.25s;
}
.main-card {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 1.5rem !important;
  margin-right: 3rem;
  box-shadow: 0.1rem 0.1rem 0.8rem 0 rgba(0, 0, 0, 0.21) !important;
  transition: 0.25s;

  .main-card-content {
    padding: 2.5rem 3.5rem 3.5rem 3.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .heading {
      display: flex;
      .patient-name {
        color: var(--text);
        width: 100%;

        .patient-lastname {
          padding-left: 0;
          font-size: 3.6rem;
          line-height: 3.2rem;
          letter-spacing: 0.024rem;
          font-weight: 500;
        }

        .patient-firstname {
          padding: 0;
          h5 {
            font-size: 2.4rem;
            line-height: 3.2rem;
            letter-spacing: 0.24px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.patient-info {
  width: 100%;
  display: flex;
  position: relative;
  padding-top: 2rem;

  .info-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .patient-info-label {
    font-size: 1.6rem;
    color: var(--text);
    font-weight: normal;
    padding-bottom: 1rem;
    padding-left: 0;
    padding-top: 0;
  }

  .patient-info-content {
    font-size: 2rem;
    color: var(--text);
    font-weight: normal;
    padding-left: 0;
  }

  .dob-phone-container {
    min-width: 18rem;
  }

  .address-container .address-line {
    margin-bottom: 1rem;
  }

  .patient-accounts {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    flex-wrap: wrap;
    padding: 3.5rem 1.5rem 2.2rem 0;

    .account-text {
      flex-grow: 0.5;
      flex-basis: 50%;
      height: 25%;
      padding: 0;
      margin-bottom: 2.5rem;
      line-height: 2.6rem;

      h5 {
        font-weight: 400;
        color: var(--text);
      }
    }
  }

  .allergies-container {
    background-color: var(--primary-light);
    padding: 3rem;
    background-color: rgba(247, 204, 209, 0.6);
    border-radius: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: auto;

    h5 {
      font-weight: 400;
      color: var(--text);
      text-align: left;
      width: 100%;
    }

    .allergy-item {
      padding-bottom: 0;
    }
  }
}

.secondary-card {
  border-radius: 1.5rem !important;
  width: 30rem;
  min-width: 28rem;
  height: 100%;
  padding: 3rem;
  box-shadow: 0.1rem 0.1rem 0.8rem 0 rgba(0, 0, 0, 0.21) !important;
  transition: 0.25s;

  .bottom {
    margin-top: 2rem;
  }
  .patient-shipping-info {
    padding: 0;
    line-height: 2.6rem;

    .subtitle {
      color: var(--text);
      line-height: 2.6rem;
      font-size: 1.4rem;
      letter-spacing: 0.013rem;
      padding: 0;
    }

    .title {
      color: var(--text);
      font-size: 2rem !important;
      letter-spacing: 0.009rem;
      line-height: 2.5rem;
      letter-spacing: 0.024rem;
      padding: 0 !important;
      font-weight: 400;
      word-break: normal !important;
    }
  }
}

.loading-bar {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 1220px) {
  .patient-info {
    flex-direction: column;

    .allergies-container {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}

@media (max-width: 1000px) {
  .patient-info {
    .info-grid {
      display: flex;
      flex-direction: column;
    }

    .patient-info-content {
      margin-bottom: 1rem;
    }
  }
}
</style>
