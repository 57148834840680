<template>
  <v-checkbox
    color="secondary"
    off-icon=""
    :rules="required !== null ? [requiredRule] : []"
    :error="error"
    :value="value"
    @change="handleInput($event)"
  >
    <template #label>
      <span class="label" :style="error ? 'color: var(--error)' : ''">
        By checking this box, I agree to the Terms and Conditions
      </span>
    </template>
  </v-checkbox>
</template>

<script lang="ts">
import Vue from "vue"
import { required as requiredRule } from "vuelidate/lib/validators"

export default Vue.extend({
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      requiredRule,
    }
  },
  methods: {
    handleInput(toggled: boolean) {
      this.$emit("input", toggled)
    },
  },
})
</script>

<style lang="scss" scoped>
.label {
  color: var(--text);
  font-size: 1.4rem;
  line-height: 1.9rem;
  letter-spacing: 0.009rem;
  transition: 0.1s;
}

.link {
  color: var(--text);
}

::v-deep {
  .v-input {
    color: var(--text) !important;
  }
  .v-icon.v-icon {
    border: 0.5rem solid var(--secondary-light);
    border-radius: 0.7rem;
    background-color: white;
  }
}
</style>
