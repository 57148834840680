<template>
  <div>
    <MandellsDialog
      class="dialog"
      persistent
      :open="open"
      :dialog-max-width="550"
      title-color="var(--secondary);"
      @dialog-closed="closeDialog"
    >
      <template #dialog-title>Update your password</template>
      <template #content>
        <div class="d-flex flex-column dialog-content">
          <MandellsTextInput
            v-model="newPassword"
            type="password"
            :error="passwordCriteriaError"
            :disabled="updatingUser"
            @blur="onPasswordBlur"
            @enter-pressed="handlePasswordUpdated"
            >New password</MandellsTextInput
          >
          <MandellsTextInput
            v-model="newPasswordConfirm"
            class="password-input"
            type="password"
            :disabled="updatingUser"
            :error="confirmationError || passwordCriteriaError"
            @blur="onConfirmPasswordBlur"
            @enter-pressed="handlePasswordUpdated"
            >Confirm new password</MandellsTextInput
          >
          <div class="password-explanation">
            Password must be at least six characters and include a lowercase letter, uppercase letter, number, and
            special character.
          </div>
          <p v-if="passwordCriteriaError || confirmationError || errorOccurred" class="error-message">
            {{ errorMessage }}
          </p>
          <SubmitBtn :loading="updatingUser" margin-bottom="0rem" :outlined="true" @submit-click="handlePasswordUpdated"
            >Sign In
          </SubmitBtn>
        </div>
      </template>
    </MandellsDialog>
  </div>
</template>

<script>
import { PASSWORD_REGEX } from "@/constants/passwordRegex"
import { updatePassword } from "@/services/userInformationServices"
import MandellsDialog from "../shared/MandellsDialog.vue"
import MandellsTextInput from "../shared/MandellsTextInput.vue"
import SubmitBtn from "../shared/SubmitBtn.vue"

export default {
  components: { MandellsDialog, SubmitBtn, MandellsTextInput },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      required: true,
    },
    password: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    updatingUser: false,
    errorOccurred: false,
    passwordCriteriaError: false,
    confirmationError: false,
    newPassword: "",
    newPasswordConfirm: "",
  }),
  computed: {
    errorMessage() {
      if (this.passwordCriteriaError) {
        return "Password does not meet criteria"
      }
      if (this.confirmationError) {
        return "Password confirmation does not match"
      }

      return "An error occurred"
    },
  },
  methods: {
    onConfirmPasswordBlur() {
      this.confirmationError = false
    },
    onPasswordBlur() {
      this.passwordCriteriaError = false
    },
    closeDialog() {
      this.$emit("password-updated", this.newPassword)
    },

    async handlePasswordUpdated() {
      this.updatingUser = true
      this.passwordCriteriaError = false
      this.errorOccurred = false
      this.confirmationError = false

      if (!PASSWORD_REGEX.test(this.newPassword)) {
        this.passwordCriteriaError = true
        this.updatingUser = false

        return
      }

      if (this.newPasswordConfirm !== this.newPassword) {
        this.confirmationError = true
        this.updatingUser = false

        return
      }

      try {
        await updatePassword(this.email, this.password, this.newPassword)
        this.closeDialog()
      } catch (error) {
        this.errorOccurred = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.terms-content {
  color: var(--text);
  font-size: 1.4rem;
  letter-spacing: 0.009rem;
  max-height: 30rem;
  overflow-y: scroll;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5rem 0 8rem 0;
}

.password-explanation {
  margin-bottom: 4rem;
}

.dialog-content {
  width: 100%;
}

.error-message {
  color: red;
  text-align: center;
}

.password-input {
  margin-bottom: 2rem;
}
</style>
