<template>
  <div class="btn-container">
    <v-btn v-if="edit" text class="column-btn" @click="handleClick('option-one')">
      <v-icon class="btn-icon">edit</v-icon>
      Edit
    </v-btn>
    <slot v-else name="option-one"></slot>
    <span v-if="(edit && remove) || (edit && signOff)" class="divider">|</span>
    <v-btn v-if="remove" text class="column-btn" @click="handleClick('option-two')">
      <v-icon class="btn-icon">delete_outline</v-icon>
      Remove
    </v-btn>
    <v-btn v-if="signOff" text class="column-btn" color="secondary" @click="handleClick('option-two')">Log Out</v-btn>
    <slot v-else name="option-two"></slot>
  </div>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    remove: {
      type: Boolean,
      default: false,
    },
    signOff: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(value: string) {
      this.$emit(`${value}`)
    },
  },
})
</script>

<style lang="scss" scoped>
.btn-container {
  display: flex;
  justify-content: left;
  align-items: center;

  .column-btn {
    text-transform: none;
    color: var(--text);
    font-size: 1.4rem;
    letter-spacing: 0.012rem;
    font-weight: 400;
    padding: 0 1rem;

    &:hover {
      color: var(--secondary);
    }

    .btn-icon {
      font-size: 1.75rem;
      padding-right: 0.25rem;
    }
  }

  .divider {
    font-size: 1.6rem;
  }
}
</style>
