<template>
  <v-btn
    class="mandells-btn"
    color="secondary"
    :href="`mailto:TeamMandells@mandellsrx.com?subject=Change Request${subjectToAppend}`"
    @click="handleChangeRequest"
  >
    <h6 class="cap"><slot></slot></h6>
  </v-btn>
</template>

<script>
export default {
  props: {
    on: {
      type: Object,
      default: () => ({}),
    },
    attrs: {
      type: Object,
      default: () => ({}),
    },
    subject: {
      type: String,
      default: "",
    },
  },
  computed: {
    subjectToAppend() {
      return this.subject === "" ? "" : ` - ${this.subject}`
    },
  },
  methods: {
    handleChangeRequest(event) {
      this.$emit("clicked", event)
    },
  },
}
</script>

<style lang="scss" scoped>
.mandells-btn {
  height: 3.6rem;
  width: 11.8rem;
  border-radius: 8.8rem;
  border: 0.1rem solid #d6d4d4;
  text-transform: none;
  box-shadow: none;
  font-weight: 400;
  padding: 0 0.8rem !important;
  color: white !important;
}
</style>
